<template>
  <v-card
    light
    v-if="Company && Template && Template.Type === 'Points'"
    class="pa-5 mb-4"
  >
    <v-row
      :style="{
        background: Company.BackgroundColor,
        color: Company.FontColor,
      }"
      class="d-flex justify-center align-center"
    >
      <span
        class="text-h6"
        :style="{
          background: Company.BackgroundColor,
          color: Company.FontColor,
        }"
      >
        <v-col cols="12" class="d-flex justify-center align-center">
          {{ ReplaceVariables(Template.Card3Title, Agreement) }}
        </v-col>
      </span>
    </v-row>
    <!-- Left Side -->
    <v-row>
      <v-col cols="12" lg="3">
        <v-row>
          <v-col cols="12">
            <v-icon large :style="{color: Company.IconColor}">
              mdi-currency-usd </v-icon
            ><span class="text-h6 ml-4">
              <b>{{
                ReplaceVariables(Template.Card3Section1Title, Agreement)
              }}</b></span
            >
          </v-col>
          <v-col
            cols="12"
            v-for="(Item, idx) in Template.Card3Section1Paragraphs"
            :key="idx"
          >
            <span>
              {{ ReplaceVariables(Item.Paragraph, Agreement) }}
            </span>
          </v-col>
        </v-row>
      </v-col>
      <!-- Right Side -->
      <v-col cols="12" lg="9" class="pa-5">
        <v-simple-table>
          <thead>
            <tr>
              <th>VIP Level</th>
              <th>Payout</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>All Levels</td>
              <td>
                <span v-if="Tracking.Override === false">50</span
                ><span v-else>{{ Tracking.OverrideNumber }}</span
                >% of the total rental income
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
      Financial: {},
      Memberships: {},
      Personal: {},
      Tracking: {},
    }
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      Company: (state) => state.Agreement.Company,
      Template: (state) => state.Agreement.Template,
      Agreement: (state) => state.Agreement,
      Disabled: (state) => state.Disabled,
      Days: (state) => state.Days,
      Months: (state) => state.Months,
      Years: (state) => state.Years,
    }),
  },
  created() {
    this.GetSections()
  },
  beforeUpdate() {
    this.GetSections()
  },
  mounted() {
    this.GetSections()
  },
  methods: {
    GetSections() {
      this.Financial = this.Agreement.Financial
      this.Memberships = this.Agreement.Memberships
      this.Personal = this.Agreement.Personal
      this.Tracking = this.Agreement.Tracking
    },
  },
}
</script>

<style></style>
