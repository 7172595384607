import {EventBus} from '@/plugins/EventBus'
const HandleErrors = {
  HandleError(error) {
    var Payload = {
      Message: '',
      Color: 'success',
    }
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      Payload.Message = error.response.data
      EventBus.$emit('ShowAlert', Payload)
      EventBus.$emit('Loading', false)
      EventBus.$emit('UpdateMessage', Payload.Message)
      throw new Error(error.response.data)
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      Payload.Message = error.request
      EventBus.$emit('ShowAlert', Payload)
      EventBus.$emit('Loading', false)
      EventBus.$emit('UpdateMessage', Payload.Message)
      throw new Error(error.request)
    } else {
      // Something happened in setting up the request that triggered an Error
      Payload.Message = error.message
      EventBus.$emit('ShowAlert', Payload)
      EventBus.$emit('Loading', false)
      EventBus.$emit('UpdateMessage', Payload.Message)
      throw new Error(error.message)
    }
  },
}
export default HandleErrors
