<template>
  <v-card>
    <v-card-text>
      <v-tabs v-model="Contact">
        <v-tab
          v-for="(Contact, idx) in Client.Contacts"
          :key="idx"
          :href="'#Contact-' + idx"
        >
          {{ Contact.LegalName }}
        </v-tab>
        <v-tabs-items v-model="Contact">
          <v-tab-item
            v-for="(Contact, idx) in Client.Contacts"
            :key="idx"
            :value="'Contact-' + idx"
          >
            <v-card>
              <v-img :src="Contact.Img"></v-img>
              <v-card-title>{{ Contact.LegalName }}</v-card-title>
              <v-card-text>
                <v-row dense>
                  <v-col cols="12"
                    >Phone:<a
                      class="white--text"
                      :href="'tel:' + Contact.DialNumber"
                    >
                      {{ Contact.Phone }}</a
                    ></v-col
                  >
                  <v-col cols="12"
                    >Email:
                    <a class="white--text" :href="'mailto:' + Contact.Email">{{
                      Contact.Email
                    }}</a>
                  </v-col>
                  <v-col cols="12"
                    >Address: {{ Contact.Address }} <br />{{ Contact.City }},
                    {{ Contact.State }} {{ Contact.ZipCode }}</v-col
                  >
                  <v-col cols="12"
                    >Web:
                    <a
                      class="white--text"
                      :href="'https://' + Contact.Website"
                      >{{ Contact.Website }}</a
                    ></v-col
                  >
                </v-row>
                <v-row>
                  <v-col cols="12"> </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
    <v-card-actions class="justify-end">
      <v-btn text @click="ShowContacts">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
      Contact: null,
    }
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
    }),
  },
  methods: {
    ShowContacts() {
      var Payload = {
        Agreement: {},
        View: false,
      }
      this.$store.commit('SetViewContacts', Payload)
    },
  },
}
</script>

<style></style>
