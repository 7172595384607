<template>
  <v-container class="fill-height" fluid>
    <v-row align="center" justify="center" dense>
      <v-col cols="12" lg="6">
        <v-card elevation="8">
          <v-card-title>
            <span class="text-h6 font-weight-light">Login</span>
          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  type="text"
                  v-model="LastName"
                  solo
                  outlined
                  placeholder="Last Name"
                  light
                  @keyup.enter.native="Login"
                ></v-text-field>
              </v-col>
              <v-col cols="12" align="center" justify="center">
                <v-btn
                  class="ma-2"
                  elevation="8"
                  color="success"
                  :loading="Loading"
                  @click="Login"
                  >Go</v-btn
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" align="center" justify="center">
                <span v-if="Message" class="overline red--text"
                  ><v-icon color="red">mdi-alert-circle-outline</v-icon>
                  {{ Message }}</span
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {EventBus} from '@/plugins/EventBus'
import HandleErrors from '@/HandleErrors'
import axios from 'axios'
export default {
  data() {
    return {
      Client: {},
      ClientId: '',
      CompanyId: '',
      LastName: '',
      Loading: false,
      Message: '',
      Response: null,
    }
  },
  async created() {
    this.ClientId = this.$route.params.ClientId
    this.CompanyId = this.$route.params.CompanyId
    EventBus.$on('Loading', (value) => {
      this.Loading = value
    })
    EventBus.$on('UpdateMessage', (Message) => {
      this.Message = Message
    })
  },
  methods: {
    async Login() {
      this.Loading = true
      var Name = this.LastName.toUpperCase()
      const options = {
        method: 'GET',
        url: `https://server-fpdn2ytyva-uc.a.run.app/api/v1/clients/${this.ClientId}`,
        params: {lastname: Name},
        headers: {
          Accept: 'application/json',
        },
      }
      await axios
        .request(options)
        .then((response) => {
          var result = response.data
          this.$store.commit('SetClient', result)
          this.$store.commit('SetAccess', true)
          EventBus.$emit('GiveAccess')
          EventBus.$emit('SetClientId', this.ClientId)
          this.Loading = false
          this.$router.push({
            path: `/${this.CompanyId}/${this.ClientId}/Dashboard`,
            params: {ClientId: this.ClientId},
          })
        })
        .catch((error) => {
          HandleErrors.HandleError(error)
        })
    },
  },
}
</script>

<style></style>
