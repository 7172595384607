<template>
  <v-card class="background mb-4" v-if="Company">
    <!-- Logo -->
    <v-row align="center" justify="center" class="pb-8 pt-8">
      <v-col cols="10" lg="8">
        <v-img contain :src="Company.Img" />
      </v-col>
    </v-row>
    <!-- Company Contact Section -->
    <v-row align="center" justify="center">
      <v-col cols="12" lg="4" align="center" justify="center">
        <div>
          <v-icon :color="Company.IconColor" large> mdi-phone </v-icon>
        </div>
        <div>
          <b :style="{color: Company.IconColor}">{{ Company.Phone }}</b>
        </div>
        <div :style="{color: Company.IconColor}">Phone</div>
      </v-col>
      <v-col cols="12" lg="4" align="center" justify="center">
        <div>
          <v-icon :color="Company.IconColor" large> mdi-email </v-icon>
        </div>
        <div>
          <b :style="{color: Company.IconColor}">{{ Company.Email }}</b>
        </div>
        <div :style="{color: Company.IconColor}">Email</div>
      </v-col>
      <v-col cols="12" lg="4" align="center" justify="center">
        <div>
          <v-icon :color="Company.IconColor" large> mdi-web </v-icon>
        </div>
        <div>
          <b :style="{color: Company.IconColor}">{{ Company.Website }}</b>
        </div>
        <div :style="{color: Company.IconColor}">Web</div>
      </v-col>
    </v-row>
    <span><br />&nbsp;</span>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {}
  },
  computed: {
    ...mapState({
      Company: (state) => state.Agreement.Company,
    }),
  },
}
</script>

<style></style>
