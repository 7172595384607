<template>
  <!-- TODO update all number fields with v-model.number -->
  <v-card>
    <div
      v-if="
        'Img' in Tracking.ScannedAgreement &&
        Tracking.ScannedAgreement.Img !== '' &&
        ViewDigital === false
      "
    >
      <v-row>
        <v-col cols="12">
          <embed class="FullHeight" :src="Tracking.ScannedAgreement.Img" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn
            v-if="Tracking.ScannedAgreement.Img !== ''"
            @click="UpdateView"
            class="warning float-right ma-2"
          >
            <span v-if="ViewDigital === false">View Digital</span>
            <span v-else>View PDF</span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <div v-else>
      <AgreementHeader></AgreementHeader>
      <AgreementTerms></AgreementTerms>
      <AgreementPayoutTable></AgreementPayoutTable>
      <AgreementPersonal></AgreementPersonal>
      <AgreementMemberships></AgreementMemberships>
      <AgreementFinancial
        v-if="Template.PaymentRequired === 'Yes'"
      ></AgreementFinancial>
      <AgreementLegal></AgreementLegal>
    </div>
  </v-card>
</template>

<script>
import axios from 'axios'
import {mapState} from 'vuex'
import AgreementHeader from '@/Views/Agreements/AgreementHeader'
import AgreementTerms from '@/Views/Agreements/AgreementTerms'
import AgreementPayoutTable from '@/Views/Agreements/AgreementPayoutTable'
import AgreementPersonal from '@/Views/Agreements/AgreementPersonal'
import AgreementMemberships from '@/Views/Agreements/AgreementMemberships'
import AgreementFinancial from '@/Views/Agreements/AgreementFinancial'
import AgreementLegal from '@/Views/Agreements/AgreementLegal'
import {EventBus} from '@/plugins/EventBus'
import {ValidateField} from '@/Views/Agreements/Validate'
import {Validate} from '@/Views/Agreements/Validate'
import HandleErrors from '@/HandleErrors'
export default {
  data() {
    return {
      Agreement: {},
      Personal: {},
      Tracking: {},
      Financial: {},
      Template: {},
      Signature: {},
      Memberships: [],
      Address: {},
      Company: {},
    }
  },
  components: {
    AgreementHeader,
    AgreementTerms,
    AgreementPayoutTable,
    AgreementPersonal,
    AgreementMemberships,
    AgreementFinancial,
    AgreementLegal,
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      ClientId: (state) => state.Client.ClientId,
      Access: (state) => state.Access,
      ViewDigital: (state) => state.ViewDigital,
    }),
  },
  watch: {
    '$route.params': {
      handler(newValue) {
        var {AgreementId} = newValue
        this.GetAgreement(AgreementId)
      },
      immediate: true,
    },
  },
  methods: {
    UpdateView() {
      if (this.ViewDigital === false) return this.$store.commit('SetView', true)
      if (this.ViewDigital === true) return this.$store.commit('SetView', false)
    },
    ValidField(item) {
      var result = ValidateField(item.Field, item.Value)
      if ('error' in result) {
        var Message = result.error.message
          .split('.')
          .join(' ')
          .split('"')
          .join('')
          .split(/(?=[A-Z])/)
          .join(' ')
        var Payload = {
          Message: Message,
          Color: 'error',
        }
        EventBus.$emit('ShowAlert', Payload)
      }
    },
    async ValidateAgreement(Agreement) {
      var result = await Validate(Agreement)
      if ('error' in result) {
        var Message = result.error.message
          .split('.')
          .join(' ')
          .split('"')
          .join('')
          .split(/(?=[A-Z])/)
          .join(' ')
        var Payload = {
          Message: Message,
          Color: 'error',
        }
        EventBus.$emit('ShowAlert', Payload)
      } else {
        this.SubmitAgreement()
      }
    },
    async GetAgreement(AgreementId) {
      var agreement = await this.Client.Agreements.filter(
        (Agreement) => Agreement.AgreementId === AgreementId
      )
      this.Agreement = agreement[0]
      this.Personal = this.Agreement.Personal
      this.Address = this.Personal.Address
      this.Tracking = this.Agreement.Tracking
      this.Financial = this.Agreement.Financial
      this.Template = this.Agreement.Template
      this.Signature = this.Agreement.Signature
      this.Memberships = this.Agreement.Memberships
      this.Company = this.Agreement.Company
      this.$store.commit('SetAgreement', this.Agreement)
    },
    CheckSigned() {
      if (
        this.Signature.Signature.length > 6 &&
        this.Tracking.Submitted === true &&
        this.Signature.SignatureURL !== ''
      ) {
        this.$store.commit('SetDisabled', true)
        return true
      } else {
        this.$store.commit('SetDisabled', false)
        return false
      }
    },
    async SubmitAgreement() {
      this.Tracking.Steps = 2
      var UpdatedAgreement = {
        Financial: this.Financial,
        Memberships: this.Memberships,
        Personal: this.Personal,
        Signature: this.Signature,
        Tracking: this.Tracking,
        Template: this.Template,
        Company: this.Company,
      }
      this.$store.commit('SetDisabled', true)
      this.Save()
      this.Tracking.Submitted = true
      this.$store.commit('SetAgreement', UpdatedAgreement)
    },
    async Submit() {
      if (this.Template.Type === 'Points') {
        for (let i = 0; i < this.Memberships.length; i++) {
          this.Memberships[i].CurrentYearWeekStatus = 'Not Using'
          this.Memberships[i].MaintFees = 1
          this.Memberships[i].MaintPaidUntil = '2022-01-01'
          this.Memberships[i].MaintUpToDate = 'Yes'
          this.Memberships[i].MortgageBalance = 0
          this.Memberships[i].MortgagePaidUntil = '2022-01-01'
          this.Memberships[i].MortgageUpToDate = 'Yes'
          this.Memberships[i].NextYearWeekStatus = 'Not Using'
          this.Memberships[i].Owners = [
            {
              NameOnDeed: 'Not Applicable',
              Status: 'None',
            },
          ]
          this.Memberships[i].ResortCity = 'Not Applicable'
          this.Memberships[i].ResortState = 'Not Applicable'
          this.Memberships[i].ResortName = 'Not Applicable'
          this.Memberships[i].Taxes = 0
          this.Memberships[i].TaxesUpToDate = 'Yes'
          this.Memberships[i].TaxesPaidUntil = '2022-01-01'
          this.Memberships[i].Type = 'Points'
        }
      } else {
        for (let i = 0; i < this.Memberships.length; i++) {
          this.Memberships[i].AccountShared = 'No'
          this.Memberships[i].CurrentYearPoints = 0
          this.Memberships[i].NextYearPoints = 0
          this.Memberships[i].Password = 'Not Applicable'
          this.Memberships[i].PayChecksTo = 'Not Applicable'
          this.Memberships[i].PointsExpDay = '01'
          this.Memberships[i].PointsExpMonth = '01'
          this.Memberships[i].UserName = 'Not Applicable'
          this.Memberships[i].VIPLevel = 'Plus'
        }
      }
      if (this.Tracking.ShowCheck === false) {
        var BankInfo = {
          BankAccountNumber: 'Not Applicable',
          BankAddress: 'Not Applicable',
          BankCheckNumber: 'Not Applicable',
          BankName: 'Not Applicable',
          BankRoutingNumber: 'Not Applicable',
        }
        this.Financial.BankInfo = BankInfo
      }
      if (this.Tracking.ShowCC === false) {
        var Cards = [
          {
            CCCVC: '111',
            CCExpMonth: '01',
            CCExpYear: '2022',
            CCNumber: '1234123412341234',
            CCName: 'Not Applicable',
            CCPaymentAmount: 1,
          },
        ]
        this.Financial.Cards = Cards
      }
      if (this.Tracking.Billing === true) {
        var Billing = {
          NameOnAccount: 'Same As Personal',
          Street1: 'Same As Personal',
          Street2: 'Same As Personal',
          City: 'Same As Personal',
          State: 'Same As Personal',
          ZipCode: 'Same As Personal',
        }
        this.Financial.Billing = Billing
      }
      var ValidateAgreement = {
        Financial: this.Financial,
        Memberships: this.Memberships,
        Personal: this.Personal,
        Signature: this.Signature,
      }
      this.ValidateAgreement(ValidateAgreement)
    },
    async Save() {
      var Agreement = {
        Financial: this.Financial,
        Memberships: this.Memberships,
        Personal: this.Personal,
        Signature: this.Signature,
      }
      var FullAgreement = {
        Company: this.Company,
        Financial: this.Financial,
        Memberships: this.Memberships,
        Personal: this.Personal,
        Signature: this.Signature,
        Template: this.Template,
        Tracking: this.Tracking,
      }

      const options = {
        method: 'POST',
        url: `https://server-fpdn2ytyva-uc.a.run.app/api/v1/clients/agreement/${this.Agreement.AgreementId}/${this.Tracking.LeadId}`,
        headers: {Accept: 'text/plain', 'Content-Type': 'application/json'},
        data: JSON.stringify(Agreement),
      }
      axios
        .request(options)
        .then((response) => {
          var Payload = {
            Message: response.statusText,
            Color: 'info',
          }
          if (response.statusText !== '') {
            EventBus.$emit('ShowAlert', Payload)
          }
        })
        .catch((error) => {
          HandleErrors.HandleError(error)
        })
      this.$store.commit('SetAgreement', FullAgreement)
      var Payload = {
        Message: 'Agreement Saved!',
        Color: 'success',
      }
      EventBus.$emit('ShowAlert', Payload)
    },
  },
  async created() {
    // Update Agreement Sections
    EventBus.$on('RefreshAgreement', async () => {
      var AgreementId = this.$route.params.AgreementId
      var Name = this.Personal.LastName.toUpperCase()
      const options = {
        method: 'GET',
        url: `https://server-fpdn2ytyva-uc.a.run.app/api/v1/clients/${this.ClientId}`,
        params: {lastname: Name},
        headers: {
          Accept: 'application/json',
        },
      }
      await axios
        .request(options)
        .then((response) => {
          var result = response.data
          this.$store.commit('SetClient', result)
        })
        .catch((error) => {
          HandleErrors.HandleError(error)
        })
      await this.GetAgreement(AgreementId)
      var Payload = {
        Message: 'Agreement Refreshed!',
        Color: 'success',
      }
      EventBus.$emit('ShowAlert', Payload)
    })
    EventBus.$on('UpdateFinancial', (Financial) => {
      this.Financial = Financial
    })
    EventBus.$on('UpdateMemberships ', (Memberships) => {
      this.Memberships = Memberships
    })
    EventBus.$on('UpdatePersonal', (Personal) => {
      this.Personal = Personal
    })
    EventBus.$on('UpdateSignature ', (Signature) => {
      this.Signature = Signature
    })
    EventBus.$on('Save', () => {
      this.Save()
    })
    EventBus.$on('SubmitAgreement', () => {
      this.Submit()
    })
    EventBus.$on('ValidateField', (item) => {
      this.ValidField(item)
    })
    if (this.Access === false) {
      var path = window.location.pathname
      if (path !== `/${this.ClientId}/Login`) {
        this.$router.push({
          path: `/${this.ClientId}/Login`,
          params: {ClientId: this.ClientId},
        })
      }
    } else {
      var AgreementId = this.$route.params.AgreementId
      await this.GetAgreement(AgreementId)
    }
    if ('SignatureDate' in this.Agreement === true) {
      this.IsDisabled = true
    }
  },
}
</script>

<style lang="scss">
.border {
  border: 1px solid;
}
.box {
  background-color: #f8f8f9;
  box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.5) inset, 0 0 0 0 transparent;
  border-radius: 0.28571429rem;
}
.background {
  background: rgb(0, 55, 62);
  background: linear-gradient(
    180deg,
    rgba(0, 55, 62, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}
.ccimage {
  height: auto;
  max-width: 200px !important;
}
.freedom {
  height: auto;
  max-width: 60px !important;
  margin-left: 5px;
}
.tall-button {
  height: 100% !important;
}
.FullHeight {
  min-height: 80vh !important;
  width: 100%;
}
</style>
