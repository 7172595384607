<template>
  <v-card
    v-if="Financial.RequiredDownPayment > 0"
    dense
    light
    class="pa-5 mb-4"
  >
    <!-- Title -->
    <v-row
      :style="{
        background: Company.BackgroundColor,
        color: Company.FontColor,
      }"
      class="d-flex justify-center align-center"
    >
      <span
        class="text-h6"
        :style="{
          background: Company.BackgroundColor,
          color: Company.FontColor,
        }"
      >
        <v-col cols="12" class="d-flex justify-center align-center">{{
          ReplaceVariables(Template.Card5Title, Agreement)
        }}</v-col>
      </span>
    </v-row>

    <!-- Billing -->

    <v-row>
      <!-- Left Side -->
      <v-col cols="12" lg="3">
        <v-row>
          <v-col cols="12">
            <v-icon large :style="{color: Company.IconColor}"> mdi-cash </v-icon
            ><span class="text-h6 ml-4">
              <b>{{
                ReplaceVariables(Template.Card5Section1Title, Agreement)
              }}</b></span
            >
          </v-col>
          <v-col
            cols="12"
            v-for="(Item, idx) in Template.Card5Section1Paragraphs"
            :key="idx"
          >
            <span>{{ ReplaceVariables(Item.Paragraph, Agreement) }}</span>
          </v-col>
        </v-row>
      </v-col>
      <!-- Right Side -->
      <v-col cols="12" lg="9" class="pa-5">
        <v-row>
          <v-col cols="12">
            <v-switch label="Same As Home Address" v-model="Tracking.Billing" />
          </v-col>
        </v-row>
        <!-- If not the same address show this row -->
        <v-row v-if="Tracking.Billing === false" dense>
          <v-col cols="12" class="mb-n4">
            <v-text-field
              label="Full Name On Account"
              v-model="Financial.Billing.NameOnAccount"
              :disabled="Disabled"
              :rules="[rules.Empty]"
              @keyup="UpdateFinancial"
              @blur="
                ValidateField(
                  'Financial.Billing.NameOnAccount',
                  Financial.Billing.NameOnAccount
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Address"
              v-model="Financial.Billing.Street1"
              :disabled="Disabled"
              :rules="[rules.Empty]"
              @keyup="UpdateFinancial"
              @blur="
                ValidateField(
                  'Financial.Billing.Street1',
                  Financial.Billing.Street1
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Address Line 2"
              v-model="Financial.Billing.Street2"
              :disabled="Disabled"
              @keyup="UpdateFinancial"
              @blur="
                ValidateField(
                  'Financial.Billing.Street2',
                  Financial.Billing.Street2
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-text-field
              label="City"
              v-model="Financial.Billing.City"
              :disabled="Disabled"
              :rules="[rules.City]"
              @keyup="UpdateFinancial"
              @blur="
                ValidateField('Financial.Billing.City', Financial.Billing.City)
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-text-field
              label="State"
              v-model="Financial.Billing.State"
              :disabled="Disabled"
              :rules="[rules.State]"
              @keyup="UpdateFinancial"
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-text-field
              label="Zip Code"
              v-model="Financial.Billing.ZipCode"
              :disabled="Disabled"
              :rules="[rules.ZipCode]"
              @keyup="UpdateFinancial"
              @blur="
                ValidateField(
                  'Financial.Billing.ZipCode',
                  Financial.Billing.ZipCode
                )
              "
              outlined
            />
          </v-col>
        </v-row>
        <!-- Credit Cards -->
        <v-row v-if="Tracking.ShowCC === true" dense>
          <v-col
            :cols="Financial.Cards.length > 1 ? '9' : '12'"
            lg="11"
            v-for="(Card, idx) in Financial.Cards"
            :key="idx"
          >
            <v-row dense>
              <v-col cols="12" lg="4" class="mb-n4">
                <v-text-field
                  v-if="
                    Tracking.ShowHalfCost === true &&
                    Tracking.ShowInhouseFinancing === false &&
                    Tracking.ShowOutsideFinancing === false
                  "
                  :id="'CCPaymentAmount' + idx"
                  :label="
                    'I Authorize One Payment Of (Minimum Payment Of ' +
                    Financial.RequiredDownPayment / 2
                  "
                  v-model.number="Card.CCPaymentAmount"
                  :disabled="Disabled"
                  :rules="[rules.Number]"
                  type="number"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.Cards[${idx}].CCPaymentAmount`,
                      Card.CCPaymentAmount
                    )
                  "
                  outlined
                />
                <v-text-field
                  v-else
                  :id="'CCPaymentAmount' + idx"
                  :label="
                    'I Authorize One Payment Of (Minimum Payment Of ' +
                    Financial.RequiredDownPayment
                  "
                  v-model.number="Card.CCPaymentAmount"
                  :disabled="Disabled"
                  :rules="[rules.Number]"
                  type="number"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.Cards[${idx}].CCPaymentAmount`,
                      Card.CCPaymentAmount
                    )
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="8" class="mb-n4">
                <v-text-field
                  :id="'CCName' + idx"
                  label="Name On Card"
                  v-model="Card.CCName"
                  :disabled="Disabled"
                  :rules="[rules.Empty]"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(`Financial.Cards[${idx}].CCName`, Card.CCName)
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="6" class="mb-n4">
                <v-text-field
                  :id="'CCNumber' + idx"
                  label="Card Number"
                  v-model="Card.CCNumber"
                  :disabled="Disabled"
                  :append-icon="ShowCCNumber ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="ShowCCNumber ? 'number' : 'password'"
                  :rules="[rules.Number, rules.CCNumber]"
                  autocomplete="cc-number"
                  maxlength="19"
                  placeholder="xxxx xxxx xxxx xxxx"
                  @click:append="ShowCCNumber = !ShowCCNumber"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.Cards[${idx}].CCNumber`,
                      Card.CCNumber
                    )
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="6" class="mb-n4">
                <v-text-field
                  :id="'CCCVC' + idx"
                  label="Card CVC"
                  v-model="Card.CCCVC"
                  :disabled="Disabled"
                  :rules="[rules.Number]"
                  type="number"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(`Financial.Cards[${idx}].CCCVC`, Card.CCCVC)
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="6" class="mb-n4">
                <v-select
                  :id="'CCExpMonth' + idx"
                  label="Expiration Month"
                  v-model="Card.CCExpMonth"
                  :items="Months"
                  :disabled="Disabled"
                  :rules="[rules.Empty]"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.Cards[${idx}].CCExpMonth`,
                      Card.CCExpMonth
                    )
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="6" class="mb-n4">
                <v-select
                  :id="'CCExpYear' + idx"
                  label="Expiration Year"
                  v-model="Card.CCExpYear"
                  :items="Years"
                  :disabled="Disabled"
                  :rules="[rules.Empty]"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.Cards[${idx}].CCExpYear`,
                      Card.CCExpYear
                    )
                  "
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
          <v-col :cols="Financial.Cards.length > 1 ? '3' : '1'" lg="1">
            <v-btn
              v-if="Financial.Cards.length <= 1"
              :disabled="Disabled"
              class="success tall-button"
              @click="AddCard"
            >
              <v-icon>mdi-credit-card-plus-outline</v-icon>
            </v-btn>
            <v-btn
              v-if="Financial.Cards.length > 1"
              :disabled="Disabled"
              class="red tall-button"
              @click="RemoveCard()"
            >
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12"
            ><p class="mb-n1">We Accept The Following Payments</p></v-col
          >
          <v-col cols="12" class="d-flex">
            <v-img contain class="ccimage" src="@/assets/img/creditcards.png" />
            <v-img contain class="freedom" src="@/assets/img/america.png" />
          </v-col>
        </v-row>
        <v-row dense v-if="Tracking.ShowCheck === true">
          <v-col cols="12">
            <v-row dense>
              <v-col cols="12" lg="4" class="mb-n4">
                <v-text-field
                  v-if="
                    Tracking.ShowHalfCost === true &&
                    Tracking.ShowInhouseFinancing === false &&
                    Tracking.ShowOutsideFinancing === false
                  "
                  id="PaymentAmount"
                  :label="
                    'I Authorize One Payment Of (Minimum Payment Of ' +
                    Financial.RequiredDownPayment
                  "
                  v-model.number="Financial.PaymentAmount"
                  :disabled="Disabled"
                  :rules="[rules.Number]"
                  type="number"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.PaymentAmount`,
                      Financial.PaymentAmount
                    )
                  "
                  outlined
                />
                <v-text-field
                  v-else
                  id="PaymentAmount"
                  :label="
                    'I Authorize One Payment Of (Minimum Payment Of ' +
                    Financial.RequiredDownPayment
                  "
                  v-model.number="Financial.PaymentAmount"
                  :disabled="Disabled"
                  :rules="[rules.Number]"
                  type="number"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.PaymentAmount`,
                      Financial.PaymentAmount
                    )
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="8" class="mb-n4">
                <v-text-field
                  id="BankName"
                  label="Bank Name"
                  v-model="Financial.BankInfo.BankName"
                  :disabled="Disabled"
                  :rules="[rules.Empty]"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.BankInfo.BankName`,
                      Financial.BankInfo.BankName
                    )
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" class="md-n4">
                <v-text-field
                  id="BankAddress"
                  label="Bank Address"
                  v-model="Financial.BankInfo.BankAddress"
                  :disabled="Disabled"
                  :rules="[rules.Empty]"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.BankInfo.BankAddress`,
                      Financial.BankInfo.BankAddress
                    )
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="4" class="mb-n4">
                <v-text-field
                  id="BankRoutingNumber"
                  label="Routing Number"
                  v-model="Financial.BankInfo.BankRoutingNumber"
                  :disabled="Disabled"
                  :rules="[rules.Number]"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.BankInfo.BankRoutingNumber`,
                      Financial.BankInfo.BankRoutingNumber
                    )
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="4" class="mb-n4">
                <v-text-field
                  id="BankAccountNumber"
                  label="Account Number"
                  v-model="Financial.BankInfo.BankAccountNumber"
                  :disabled="Disabled"
                  :rules="[rules.Number]"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.BankInfo.BankAccountNumber`,
                      Financial.BankInfo.BankAccountNumber
                    )
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12" lg="4" class="mb-n4">
                <v-text-field
                  id="BankCheckNumber"
                  label="Check Number"
                  v-model="Financial.BankInfo.BankCheckNumber"
                  :disabled="Disabled"
                  :rules="[rules.Number]"
                  @keyup="UpdateFinancial"
                  @blur="
                    ValidateField(
                      `Financial.BankInfo.BankCheckNumber`,
                      Financial.BankInfo.BankCheckNumber
                    )
                  "
                  outlined
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- In House Financing -->

    <v-row
      v-if="
        Tracking.ShowInhouseFinancing === true ||
        Tracking.ShowOutsideFinancing === true
      "
      dense
    >
      <!-- Left Side -->
      <v-col cols="12" lg="3">
        <v-row dense>
          <v-col cols="12">
            <v-icon large :style="{color: Company.IconColor}">
              mdi-currency-usd </v-icon
            ><span class="text-h6 ml-4">
              <b>{{
                ReplaceVariables(Template.Card5Section1Title, Agreement)
              }}</b></span
            >
          </v-col>
          <v-col
            cols="12"
            v-for="(Item, idx) in Template.Card5Section1Paragraphs"
            :key="idx"
          >
            <span>{{ ReplaceVariables(Item.Paragraph, Agreement) }}</span>
          </v-col>
        </v-row>
      </v-col>
      <!-- Right Side -->
      <v-col cols="12" lg="9">
        <v-row dense>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Amount Financed"
              v-model.number="Financial.FinanceAmount"
              disabled
              outlined
              @keyup="UpdateFinancial"
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Finance Fee"
              disabled
              v-model.number="Financial.FinanceFee"
              @keyup="UpdateFinancial"
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Total Cost"
              disabled
              v-model.number="Financial.FinanceTotal"
              @keyup="UpdateFinancial"
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Terms"
              v-model="Financial.FinanceTerms"
              disabled
              @keyup="UpdateFinancial"
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Monthly Payment"
              v-model.number="Financial.MonthlyPayment"
              disabled
              @keyup="UpdateFinancial"
              outlined
            />
          </v-col>
        </v-row>
        <v-row v-if="Tracking.ShowOutsideFinancing === true">
          <v-col
            cols="12"
            v-for="(Item, idx) in Template.FinancingTerms"
            :key="idx"
          >
            <p>
              {{ ReplaceVariables(Item.Paragraph, Agreement) }}
            </p>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
import {EventBus} from '@/plugins/EventBus'
export default {
  data() {
    return {
      Financial: {},
      Memberships: {},
      Personal: {},
      Tracking: {},
      ShowCCNumber: true,
      rules: {
        Empty: (value) => value.length > 0 || 'Can Not Be Blank',
        CCNumber: (v) => v.length >= 13 || 'Min 13 numbers',
        City: (value) =>
          value.length >= 2 || 'City Must Be At Least 2 Characters',
        State: (value) =>
          value.length >= 2 || 'State Must Be At Least 2 Characters',
        ZipCode: (value) =>
          value.length >= 2 || 'Zip Code Must Be At Least 5 Characters',
        Number: (value) => {
          const pattern = /^\d+$/
          return pattern.test(value) || 'Must Be A Number'
        },
      },
    }
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      Company: (state) => state.Agreement.Company,
      Template: (state) => state.Agreement.Template,
      Agreement: (state) => state.Agreement,
      Disabled: (state) => state.Disabled,
      Days: (state) => state.Days,
      Months: (state) => state.Months,
      Years: (state) => state.Years,
    }),
  },
  created() {
    this.GetSections()
  },
  beforeUpdate() {
    this.GetSections()
  },
  mounted() {
    this.GetSections()
  },
  methods: {
    ValidateField(Field, Value) {
      var Payload = {
        Field: Field,
        Value: Value,
      }
      EventBus.$emit('ValidateField', Payload)
    },
    GetSections() {
      this.Financial = this.Agreement.Financial
      this.Memberships = this.Agreement.Memberships
      this.Personal = this.Agreement.Personal
      this.Tracking = this.Agreement.Tracking
    },
    RemoveCard() {
      this.Financial.Cards.pop()
    },
    AddCard() {
      var NewCard = {
        CCNumber: '',
        CCCVC: '',
        CCExpMonth: '',
        CCExpYear: '',
        CCPaymentAmount: 0,
      }
      this.Financial.Cards.push(NewCard)
    },
    UpdateFinancial() {
      EventBus.$emit('UpdateFinancial', this.Financial)
    },
  },
}
</script>

<style></style>
