<template>
  <v-row>
    <!-- Agreements List -->
    <v-col cols="12">
      <v-card class="px-4">
        <v-card-title>Agreements</v-card-title>
        <v-row>
          <v-col
            cols="12"
            lg="4"
            v-for="(Agreement, idx) in Agreements"
            :key="idx"
          >
            <!-- BUG Cannot update steps via API need to be updated on the gateway side -->
            <v-card color="primary">
              <v-card-title class="d-flex justify-center align-center">
                <span class="text-h6 font-weight-heavy">
                  {{ ReplaceVariables(Agreement.Template.Title, Agreement) }}
                </span>
              </v-card-title>
              <v-card-actions class="d-flex justify-center align-center">
                <v-btn
                  color="secondary"
                  @click="ChangeRoute(Agreement.AgreementId)"
                >
                  View Agreement
                </v-btn>
                <v-btn color="secondary" @click="ShowContacts(Agreement)">
                  View Contacts
                </v-btn>
              </v-card-actions>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-stepper
                      vertical
                      v-model="Agreement.Tracking.Steps"
                      v-if="Agreement.Template.Type === 'Deed'"
                      light
                    >
                      <v-stepper-step
                        :complete="Agreement.Tracking.Steps > 1"
                        step="1"
                      >
                        Sign Agreement
                      </v-stepper-step>
                      <v-stepper-content step="1"></v-stepper-content>
                      <v-stepper-step
                        :complete="Agreement.Tracking.Steps > 2"
                        step="2"
                      >
                        Submit Payment
                      </v-stepper-step>
                      <v-stepper-content step="2"></v-stepper-content>
                      <v-stepper-step
                        :complete="Agreement.Tracking.Steps > 3"
                        step="3"
                      >
                        Submit Additional Paperwork
                      </v-stepper-step>
                      <v-stepper-content step="3"></v-stepper-content>
                      <v-stepper-step
                        :complete="Agreement.Tracking.Steps > 4"
                        step="4"
                      >
                        Enjoy Life Without A Timeshare
                      </v-stepper-step>
                    </v-stepper>
                    <v-stepper
                      vertical
                      v-model="Agreement.Tracking.Steps"
                      v-else
                      light
                    >
                      <v-stepper-step
                        :complete="Agreement.Tracking.Steps > 1"
                        step="1"
                      >
                        Sign Agreement
                      </v-stepper-step>
                      <v-stepper-content step="1"></v-stepper-content>
                      <v-stepper-step
                        :complete="Agreement.Tracking.Steps > 2"
                        step="2"
                      >
                        Wait For Your Check
                      </v-stepper-step>
                      <v-stepper-content step="2"></v-stepper-content>
                    </v-stepper>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
      Agreements: [],
    }
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      Access: (state) => state.Access,
      CompanyId: (state) => state.Company.CompanyId,
    }),
  },
  created() {
    this.Agreements = this.Client.Agreements.filter(
      (Agreement) => Agreement.Company.CompanyId === this.CompanyId
    )
  },
  methods: {
    ShowContacts(Agreement) {
      var Payload = {
        Agreement: Agreement,
        View: true,
      }
      this.$store.commit('SetViewContacts', Payload)
    },
    ChangeRoute(Route) {
      if (
        location.pathname ===
        `/${this.CompanyId}/${this.Client.ClientId}/${Route}`
      ) {
        return
      } else {
        this.$router.push({
          path: `/${this.CompanyId}/${this.Client.ClientId}/${Route}`,
        })
      }
    },
  },
}
</script>

<style></style>
