<template>
  <v-card v-if="Company" light class="pa-5 mb-4">
    <!-- Title -->
    <v-row
      :style="{
        background: Company.BackgroundColor,
        color: Company.FontColor,
      }"
      class="d-flex justify-center align-center"
    >
      <span
        class="text-h6"
        :style="{
          background: Company.BackgroundColor,
          color: Company.FontColor,
        }"
      >
        <v-col cols="12" class="d-flex justify-center align-center">
          {{ ReplaceVariables(Template.Card6Title, Agreement) }}
        </v-col>
      </span>
    </v-row>
    <!-- Terms -->
    <v-row class="d-flex justify-center align-center">
      <v-col cols="10">
        <v-icon large :style="{color: Company.IconColor}"
          >mdi-scale-balance</v-icon
        >
        <span class="title ml-4">
          <b>
            {{ ReplaceVariables(Template.Card6Section1Title, Agreement) }}
          </b>
        </span>
      </v-col>
      <v-col
        cols="10"
        v-for="(Item, idx) in Template.Card6Section1Paragraphs"
        :key="idx"
      >
        <span>{{ ReplaceVariables(Item.Paragraph, Agreement) }}</span>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="10">
        <div v-for="(Item, idx) in Template.Terms" :key="idx">
          <p>
            <b v-if="'Title' in Item === true && Item.Title !== ''"
              >{{ ReplaceVariables(Item.Title, Agreement) }}:</b
            >
            {{ ReplaceVariables(Item.Paragraph, Agreement) }}
          </p>
        </div>
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" lg="8">
            <div class="box mt-4">
              <v-col cols="12"
                ><v-icon large>mdi-signature</v-icon
                ><b class="ml-4">Agreement</b></v-col
              >
              <v-col cols="12" class="px-2">
                By ("digitally signing") your name below you agree to the terms
                and conditions herein of this contract, and will be deemed a
                valid signature.
              </v-col>
              <v-col cols="12" class="m-0 p-0" id="sig">
                <div class="sig-container" v-if="Signature.SignatureURL === ''">
                  <vueSignature
                    id="SignatureURL"
                    ref="signature"
                    :h="'300px'"
                    :w="SignatureWidth"
                  ></vueSignature>
                </div>
                <v-img contain v-else :src="Signature.SignatureURL" />
              </v-col>
              <v-col
                cols="12"
                class="d-flex flex-wrap justify-center align-center"
              >
                <v-btn
                  class="warning mx-1 mb-2"
                  v-if="Tracking.Submitted === false"
                  @click="clear"
                  >Clear</v-btn
                >
                <v-btn
                  class="warning mx-1 mb-2"
                  v-if="Signature.SignatureURL === ''"
                  @click="undo"
                  >Undo</v-btn
                >
              </v-col>
              <v-col cols="12">
                <v-text-field
                  id="Signature"
                  label="Your Name"
                  type="text"
                  :disabled="Disabled"
                  :rules="[rules.Signature]"
                  required
                  v-model="Signature.Signature"
                  @blur="
                    ValidateField('Signature.Signature', Signature.Signature)
                  "
                  outlined
                />
              </v-col>
              <v-col cols="12">
                <span
                  v-if="
                    'Signature' in Agreement === true &&
                    'SignDate' in Agreement === true
                  "
                  >Signed:
                  {{
                    Signature.SignDate | moment('dddd, MMMM Do YYYY, h:mm:ss a')
                  }}</span
                >
              </v-col>
              <v-col cols="12" v-if="Signature.SignatureURL !== ''">
                Signing IP Address: {{ IP }}
              </v-col>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-btn
          v-if="Tracking.ScannedAgreement.Img !== ''"
          @click="UpdateView"
          class="warning float-right ma-2"
        >
          <span v-if="ViewDigital === false">View Digital</span>
          <span v-else>View PDF</span>
        </v-btn>
        <v-btn @click="Refresh" class="warning float-right ma-2">Refresh</v-btn>
        <v-btn
          @click="Submit"
          :disabled="Disabled"
          class="warning float-right ma-2"
          >Submit</v-btn
        >
        <v-btn
          @click="Save"
          :disabled="Disabled"
          class="success float-right ma-2"
          >Save</v-btn
        >
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
import {EventBus} from '@/plugins/EventBus'
import vueSignature from 'vue-signature'
import axios from 'axios'
import moment from 'moment'
import HandleErrors from '@/HandleErrors'
export default {
  components: {
    vueSignature,
  },
  data() {
    return {
      Financial: {},
      Memberships: {},
      Personal: {},
      Tracking: {},
      Signature: {},
      IP: '',
      rules: {
        Signature: (value) =>
          value.length > 3 || 'Signature Can Not Be Under 3 Characters',
      },
      SignatureWidth: '100%',
    }
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      Company: (state) => state.Agreement.Company,
      Template: (state) => state.Agreement.Template,
      Agreement: (state) => state.Agreement,
      Disabled: (state) => state.Disabled,
      Days: (state) => state.Days,
      Months: (state) => state.Months,
      Years: (state) => state.Years,
      ViewDigital: (state) => state.ViewDigital,
    }),
  },
  created() {
    this.GetSections()
    let el = document.getElementById('sig')
    let outer = el.offsetWidth
    var five = outer * 0.02
    var width = (outer - five).toString() + 'px'
    console.log(width)
    this.SignatureWidth = width
  },
  beforeUpdate() {
    this.GetSections()
    let el = document.getElementById('sig')
    let outer = el.offsetWidth
    var five = outer * 0.02
    var width = (outer - five).toString() + 'px'
    console.log(width)
    this.SignatureWidth = width
  },
  mounted() {
    this.GetSections()
    axios
      .get('https://www.cloudflare.com/cdn-cgi/trace')
      .then((response) => {
        this.IP = response.data.substring(
          response.data.search('ip=') + 3,
          response.data.search('ts=')
        )
      })
      .catch((error) => {
        HandleErrors.HandleError(error)
      })
    let el = document.getElementById('sig')
    let outer = el.offsetWidth
    var five = outer * 0.02
    var width = (outer - five).toString() + 'px'
    console.log(width)
    this.SignatureWidth = width
  },
  methods: {
    UpdateView() {
      if (this.ViewDigital === false) return this.$store.commit('SetView', true)
      if (this.ViewDigital === true) return this.$store.commit('SetView', false)
    },
    Refresh() {
      EventBus.$emit('RefreshAgreement')
    },
    ValidateField(Field, Value) {
      var Payload = {
        Field: Field,
        Value: Value,
      }
      EventBus.$emit('ValidateField', Payload)
    },
    Submit() {
      if (this.$refs.signature.isEmpty() === true) {
        this.$vToastify.error(
          'You need to sign before you can submit your signature'
        )
        return
      }
      if (this.Signature.Signature === '') {
        this.$vToastify.error('Please Type Your Name')
        return
      }
      var svg = this.$refs.signature.save('image/svg+xml')
      this.Signature.SignatureURL = svg
      this.Signature.SignatureIP = this.IP
      this.Signature.SignDate = moment(new Date()).format('yyyy-MM-DD')
      this.UpdateSignature()
      setTimeout(() => {
        EventBus.$emit('SubmitAgreement')
      }, 1000)
    },
    GetSections() {
      this.Financial = this.Agreement.Financial
      this.Memberships = this.Agreement.Memberships
      this.Personal = this.Agreement.Personal
      this.Tracking = this.Agreement.Tracking
      this.Signature = this.Agreement.Signature
    },
    Save() {
      this.UpdateSignature()
      setTimeout(() => {
        EventBus.$emit('Save')
      }, 1000)
    },
    clear() {
      if (this.Signature.SignatureURL === '') {
        this.$refs.signature.clear()
      }
      this.Signature.SignatureURL = ''
      this.UpdateSignature()
    },
    undo() {
      this.$refs.signature.undo()
    },
    UpdateSignature() {
      EventBus.$emit('UpdateSignature', this.Signature)
    },
  },
}
</script>

<style>
.signature {
  position: relative;
}
.sig-container {
  max-width: 1000px;
}
</style>
