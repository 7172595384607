<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-nav-icon
        v-if="Access === true"
        @click="drawer = true"
      ></v-app-bar-nav-icon>
      <v-toolbar-title>{{ Company.LegalName }}</v-toolbar-title>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      color="secondary"
      absolute
      temporary
    >
      <v-img
        contain
        class="px-6"
        max-width="100%"
        :src="Company.Img"
        @click="VisitSite"
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              color="grey lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
      </v-img>
      <v-list>
        <v-list-item link @click="ChangeRoute('Dashboard')">
          <v-list-item-icon>
            <v-icon>mdi-view-dashboard</v-icon>
          </v-list-item-icon>
          <v-list-item-title prepend-icon="mdi-view-dashboard">
            Dashboard
          </v-list-item-title>
        </v-list-item>
        <v-list-group
          active-class="info--text"
          :value="true"
          prepend-icon="mdi-view-dashboard"
        >
          <template v-slot:activator>
            <v-list-item-title>Agreements</v-list-item-title>
          </template>
          <v-list-item v-for="(Agreement, idx) in Agreements" :key="idx" link>
            <v-list-item-content>
              <v-list-item-title
                @click="ChangeRoute(Agreement.AgreementId)"
                prepend-icon="mdi-view-dashboard"
                color="accent"
                v-text="ReplaceVariables(Agreement.Template.Title, Agreement)"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
      </v-list>
      <template v-slot:append>
        <div class="px-4 text-subtitle-2">
          &copy; {{ new Date().getFullYear() }}
          <a class="white--text ml-1" @click="VisitSite">{{
            Company.LegalName
          }}</a>
        </div>
      </template>
    </v-navigation-drawer>
    <v-main>
      <v-container>
        <router-view />
        <v-dialog
          persistent
          v-model="ViewContacts"
          transition="dialog-top-transition"
          max-width="600"
        >
          <Contacts />
        </v-dialog>
      </v-container>
      <v-snackbar
        v-model="Alert.Show"
        :timeout="Alert.Timeout"
        top
        :color="Alert.Color"
      >
        <b>{{ Alert.Message }}</b>

        <template v-slot:action="{attrs}">
          <v-btn
            color="secondary"
            text
            v-bind="attrs"
            @click="Alert.Show = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <v-overlay :value="Loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import {mapState} from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import {EventBus} from '@/plugins/EventBus'
import axios from 'axios'
import HandleErrors from '@/HandleErrors'
import Contacts from '@/Views/Contacts/Contacts.vue'
Vue.mixin({
  methods: {
    ReplaceVariables(value, Agreement) {
      var Personal = Agreement.Personal
      var Financial = Agreement.Financial
      var Company = Agreement.Company
      var Tracking = Agreement.Tracking
      if (value !== undefined && typeof value === 'string') {
        if (value.includes('{{') === true) {
          return value
            .split('{{AgreementFirstName}}')
            .join(Personal.FirstName)
            .split('{{AgreementLastName}}')
            .join(Personal.LastName)
            .split('{{AgreementFinanceAmount}}')
            .join(Financial.FinanceAmount)
            .split('{{AgreementFinanceRate}}')
            .join(Financial.FinanceRate)
            .split('{{AgreementFinanceTerms}}')
            .join(Financial.FinanceTerms)
            .split('{{AgreementFinanceTotal}}')
            .join(Financial.FinanceTotal)
            .split('{{AgreementMonthlyPayment}}')
            .join(`$${Financial.MonthlyPayment}`)
            .split('{{CompanyName}}')
            .join(Company.Name)
            .split('{{CompanyLegalName}}')
            .join(Company.LegalName)
            .split('{{CompanyPhone}}')
            .join(Company.Phone)
            .split('{{CompanyEmail}}')
            .join(Company.Email)
            .split('{{CompanyWebsite}}')
            .join(Company.Website)
            .split('{{CompanyAddress}}')
            .join(Company.Address)
            .split('{{CompanyAddress2}}')
            .join(Company.Address2)
            .split('{{CompanyCity}}')
            .join(Company.City)
            .split('{{CompanyState}}')
            .join(Company.State)
            .split('{{CompanyZipCode}}')
            .join(Company.ZipCode)
            .split('{{ResortName}}')
            .join(Tracking.ResortName)
        } else {
          return value
        }
      } else {
        return
      }
    },
  },
})
export default {
  components: {Contacts},
  name: 'App',
  data() {
    return {
      Loading: false,
      CompanyData: null,
      drawer: false,
      Alert: {
        Show: false,
        Message: '',
        Color: 'success',
        Timeout: 6000,
      },
      Agreements: [],
    }
  },
  async created() {
    this.FetchCompany()
    this.GetYears()
    EventBus.$on('Loading', (value) => {
      this.Loading = value
    })
    EventBus.$on('ShowAlert', (Payload) => {
      this.Alert.Message = Payload.Message
      this.Alert.Color = Payload.Color
      this.Alert.Show = true
    })
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      Company: (state) => state.Company,
      Access: (state) => state.Access,
      CompanyId: (state) => state.Company.CompanyId,
      ViewContacts: (state) => state.ViewContacts,
    }),
  },
  watch: {
    Client(value) {
      this.Agreements = value.Agreements.filter(
        (Agreement) => Agreement.Company.CompanyId === this.CompanyId
      )
    },
    Access(value) {
      if (value === false) {
        if (
          location.pathname !==
          `/${this.CompanyId}/${this.Client.ClientId}/Login`
        ) {
          this.$router.push({
            path: `/${this.CompanyId}/${this.Client.ClientId}/Login`,
          })
        } else {
          return
        }
      } else return
    },
  },
  methods: {
    VisitSite() {
      window.open('https://' + this.Company.Website, '_blank')
    },
    GetYears() {
      var CurrentDate = null
      CurrentDate = moment(new Date()).toDate()
      var CurrentYear = moment(CurrentDate).format('YYYY')
      var Years = []
      Years.push(CurrentYear)
      for (let i = 0; i < 10; i++) {
        if (i === 0) {
          var nextyear = moment(CurrentYear).add(1, 'years').format('YYYY')
          Years.push(nextyear)
        } else {
          nextyear = moment(nextyear).add(1, 'years').format('YYYY')
          Years.push(nextyear)
        }
      }
      this.$store.commit('SetYears', Years)
    },
    async FetchCompany() {
      var CompanyId = location.pathname.split('/')[1]
      const options = {
        method: 'GET',
        url: `https://server-fpdn2ytyva-uc.a.run.app/api/v1/companies/${CompanyId}`,
        headers: {Accept: 'application/json'},
      }
      await axios
        .request(options)
        .then((response) => {
          this.CompanyData = response.data
          var theme = {
            primary: this.CompanyData.WebColors.Primary,
            secondary: this.CompanyData.WebColors.Secondary,
            accent: this.CompanyData.WebColors.Accent,
            error: this.CompanyData.WebColors.Error,
            info: this.CompanyData.WebColors.Info,
            success: this.CompanyData.WebColors.Success,
            warning: this.CompanyData.WebColors.Warning,
          }
          this.$vuetify.theme.themes.dark = theme
          this.$store.commit('SetCompany', this.CompanyData)
        })
        .catch((error) => {
          HandleErrors.HandleError(error)
        })
    },
    ChangeRoute(Route) {
      if (
        location.pathname ===
        `/${this.CompanyId}/${this.Client.ClientId}/${Route}`
      ) {
        return
      } else {
        this.$router.push({
          path: `/${this.CompanyId}/${this.Client.ClientId}/${Route}`,
        })
      }
    },
  },
}
</script>
