<template>
  <v-card
    light
    v-if="Template && Template.Context.length > 0"
    class="pa-5 mb-4"
  >
    <!-- Title Section -->
    <v-row
      :style="{
        background: Company.BackgroundColor,
        color: Company.FontColor,
      }"
      class="d-flex justify-center align-center"
    >
      <span
        class="text-h6"
        :style="{
          background: Company.BackgroundColor,
          color: Company.FontColor,
        }"
      >
        <v-col cols="12" class="d-flex justify-center align-center">{{
          ReplaceVariables(Template.Card2Title, Agreement)
        }}</v-col>
      </span>
      <!-- Info Section -->
    </v-row>
    <v-row>
      <v-col cols="12" class="pa-5">
        <div class="text-h6">
          <b>
            {{ ReplaceVariables(Template.Card2Section1Title, Agreement) }}
          </b>
        </div>
        <div class="text-subtitle-1">
          <p>
            {{ ReplaceVariables(Template.Card2Section1SubTitle, Agreement) }}
          </p>
        </div>
      </v-col>
    </v-row>
    <!-- Content Section -->
    <v-row>
      <v-col cols="12" class="pa-5">
        <ul>
          <li v-for="(Item, idx) in Template.Context" :key="idx">
            {{ ReplaceVariables(Item.Paragraph, Agreement) }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
export default {
  data() {
    return {
      Financial: {},
      Memberships: {},
      Personal: {},
      Tracking: {},
    }
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      Company: (state) => state.Agreement.Company,
      Template: (state) => state.Agreement.Template,
      Agreement: (state) => state.Agreement,
      Disabled: (state) => state.Disabled,
      Days: (state) => state.Days,
      Months: (state) => state.Months,
      Years: (state) => state.Years,
    }),
  },
  created() {
    this.GetSections()
  },
  beforeUpdate() {
    this.GetSections()
  },
  mounted() {
    this.GetSections()
  },
  methods: {
    GetSections() {
      this.Financial = this.Agreement.Financial
      this.Memberships = this.Agreement.Memberships
      this.Personal = this.Agreement.Personal
      this.Tracking = this.Agreement.Tracking
    },
  },
}
</script>

<style lang="scss" scoped>
ul {
  list-style-type: disc;
  display: inline-block;
}
</style>
