import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
Vue.use(Vuetify)

var WebColors = {
  Primary: '#1976D2',
  Secondary: '#212121',
  Accent: '#82B1FF',
  Error: '#FF5252',
  Info: '#007ab8',
  Success: '#4CAF50',
  Warning: '#FFC107',
}

export default new Vuetify({
  theme: {
    dark: true,
    themes: {
      dark: {
        primary: WebColors.Primary,
        secondary: WebColors.Secondary,
        accent: WebColors.Accent,
        error: WebColors.Error,
        info: WebColors.Info,
        success: WebColors.Success,
        warning: WebColors.Warning,
      },
    },
  },
})
