<template>
  <v-card v-if="Company" light class="pa-5 mb-4">
    <v-row
      :style="{
        background: Company.BackgroundColor,
        color: Company.FontColor,
      }"
      class="d-flex justify-center align-center"
    >
      <span
        class="text-h6"
        :style="{
          background: Company.BackgroundColor,
          color: Company.FontColor,
        }"
      >
        <v-col cols="12" class="d-flex justify-center align-center">
          Memberships
        </v-col>
      </span>
    </v-row>
    <v-row>
      <!-- Left Section -->
      <v-col cols="12" lg="3">
        <v-row>
          <v-col cols="12">
            <v-icon large :style="{color: Company.IconColor}">
              mdi-account-box </v-icon
            ><span class="text-xl-h5 ml-4">
              <b>{{
                ReplaceVariables(Template.Card4Section2Title, Agreement)
              }}</b></span
            >
          </v-col>
          <v-col
            cols="12"
            v-for="(Item, idx) in Template.Card4Section2Paragraphs"
            :key="idx"
          >
            <span>{{ ReplaceVariables(Item.Paragraph, Agreement) }}</span>
          </v-col>
        </v-row>
      </v-col>
      <!-- Right Section -->
      <v-col
        cols="12"
        lg="9"
        v-if="Template.Type === 'Points'"
        class="pa-5"
        :style="{
          'border-color': Company.BackgroundColor,
        }"
      >
        <v-row
          v-for="(membership, idx) in Memberships"
          :key="idx"
          class="border"
        >
          <v-col cols="12">
            <h4>
              <b>Miscellaneous</b>
            </h4>
          </v-col>
          <v-col cols="12">
            <v-text-field
              label="Make Checks Payable To"
              placeholder="Make Checks Payable To"
              @keyup="UpdateMemberships"
              v-model="membership.PayChecksTo"
              :rules="[rules.Empty]"
              @blur="
                ValidateField(
                  `Memberships[${idx}].PayChecksTo`,
                  membership.PayChecksTo
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-select
              label="VIP Status"
              :items="VIPLevels"
              v-model="membership.VIPLevel"
              :rules="[rules.Empty]"
              :disabled="Disabled"
              @blur="
                ValidateField(
                  `Memberships[${idx}].VIPLevel`,
                  membership.VIPLevel
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Annual Points Recieved"
              v-model.number="membership.YearlyPoints"
              :rules="[rules.Number]"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].YearlyPoints`,
                  membership.YearlyPoints
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              :label="membership.CurrentYear + ' Points Allocation'"
              v-model.number="membership.CurrentYearPoints"
              :rules="[rules.Number]"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].CurrentYearPoints`,
                  membership.CurrentYearPoints
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              :label="membership.NextYear + ' Points Allocation'"
              :rules="[rules.Number]"
              v-model.number="membership.NextYearPoints"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].NextYearPoints`,
                  membership.NextYearPoints
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Contract Number"
              v-model="membership.ContractNumber"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].ContractNumber`,
                  membership.ContractNumber
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Owner Number"
              v-model="membership.OwnerNumber"
              :rules="[rules.Empty]"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].OwnerNumber`,
                  membership.OwnerNumber
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-select
              label="Points Expiration Month"
              :items="Months"
              item-text="text"
              item-value="value"
              @input="membership.PointsExpDay = ''"
              v-model="membership.PointsExpMonth"
              :rules="[rules.Empty]"
              :disabled="Disabled"
              @blur="
                ValidateField(
                  `Memberships[${idx}].PointsExpMonth`,
                  membership.PointsExpMonth
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-select
              :disabled="Disabled"
              :items="Days"
              label="Points Expiration Day"
              v-model="membership.PointsExpDay"
              :rules="[rules.Empty]"
              @blur="
                ValidateField(
                  `Memberships[${idx}].PointsExpDay`,
                  membership.PointsExpDay
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-select
              label="How Often Do Your Points Expire?"
              v-model="membership.PointsExpInterval"
              :rules="[rules.Empty]"
              :disabled="Disabled"
              :items="ExpInterval"
              @blur="
                ValidateField(
                  `Memberships[${idx}].PointsExpInterval`,
                  membership.PointsExpInterval
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="User Name"
              v-model="membership.UserName"
              :rules="[rules.Empty]"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].UserName`,
                  membership.UserName
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Password"
              type="password"
              v-model="membership.Password"
              :rules="[rules.Empty]"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].Password`,
                  membership.Password
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12">
            <div class="box pa-4">
              <p>
                <v-icon large color="red">mdi-alert</v-icon>
                <span class="text-xl-h5 ml-4">
                  <b>Important</b>
                </span>
                <br />
                Do any other companies or individuals use your account or have
                access to it?
              </p>
              <hr />
              <p>
                Failure to notify us of any outside activity in your account may
                cause:
              </p>
              <ul>
                <li>Reservation cancellations</li>
                <li>Not enough points being available</li>
                <li>Confusion and/or undue stress for guests</li>
              </ul>
              <v-select
                class="mt-4"
                label="Account Shared"
                v-model="membership.AccountShared"
                :rules="[rules.Empty]"
                :items="UpToDate"
                :disabled="Disabled"
                @blur="
                  ValidateField(
                    `Memberships[${idx}].AccountShared`,
                    membership.AccountShared
                  )
                "
                outlined
              >
              </v-select>
            </div>
          </v-col>
          <v-col cols="12" v-if="Memberships.length > 1">
            <v-btn
              :disabled="Disabled"
              class="red"
              @click="RemoveMembership(idx)"
            >
              Remove Membership
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-btn :disabled="Disabled" class="success" @click="PushMembership">
              <span v-if="Memberships.length === 0">Add Membership</span>
              <span v-else>Add Another Membership</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4>
              <b>Special Instructions / Addendum</b>
            </h4>
            <hr
              class="border"
              :style="{
                'border-color': Company.BackgroundColor,
              }"
            />
          </v-col>
          <v-col
            cols="12"
            v-if="
              Tracking.ShowHalfCost === true &&
              Tracking.ShowInhouseFinancing === false &&
              Tracking.ShowOutsideFinancing === false
            "
          >
            Client agrees to pay half of Points Reimbursement Fee of ({{
              ((Financial.ClosingCost + Financial.RequiredDownPayment) / 2)
                | toCurrency
            }}) upon contract signing. Remaining half of Points Reimbursement
            Fee of ({{
              ((Financial.ClosingCost + Financial.RequiredDownPayment) / 2)
                | toCurrency
            }}) due once 1st reimbursement check has been received by client.
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        lg="9"
        v-else
        class="pa-5"
        :style="{
          'border-color': Company.BackgroundColor,
        }"
      >
        <v-row
          v-for="(membership, idx) in Memberships"
          :key="idx"
          class="border"
        >
          <v-col cols="12">
            <h4>
              <b>Miscellaneous</b>
            </h4>
          </v-col>
          <v-col cols="12">
            <v-row dense v-for="(owner, idx2) in membership.Owners" :key="idx2">
              <v-col cols="9" lg="11">
                <v-row dense>
                  <v-col cols="12" lg="6" class="mb-n4">
                    <v-text-field
                      :id="'NameOnDeed' + idx2"
                      label="Name On Deed"
                      v-model="owner.NameOnDeed"
                      :disabled="Disabled"
                      :rules="[rules.Empty]"
                      @keyup="UpdateMemberships"
                      @blur="
                        ValidateField(
                          `Memberships[${idx}].Owners[${idx2}].NameOnDeed`,
                          owner.NameOnDeed
                        )
                      "
                      outlined
                    />
                  </v-col>
                  <v-col cols="12" lg="6" class="mb-n4">
                    <v-select
                      label="Status (if applicable)"
                      v-model="owner.Status"
                      :disabled="Disabled"
                      :items="OwnerStatus"
                      :rules="[rules.Empty]"
                      @blur="
                        ValidateField(
                          `Memberships[${idx}].Owners[${idx2}].Status`,
                          owner.Status
                        )
                      "
                      outlined
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="3" lg="1" class="mb-4">
                <v-btn
                  class="red tall-button"
                  :disabled="Disabled"
                  @click="DeleteOwner(idx, idx2)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="mb-n4">
            <v-btn
              class="mt-n6"
              elevation="8"
              color="success"
              :disabled="Disabled"
              @click="AddOwner(idx)"
            >
              Add Owner
            </v-btn>
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              :id="'ResortName' + idx"
              label="Resort Name"
              v-model="membership.ResortName"
              :disabled="Disabled"
              :rules="[rules.Empty]"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].ResortName`,
                  membership.ResortName
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="3" class="mb-n4">
            <v-text-field
              :id="'ResortCity' + idx"
              label="Resort City"
              v-model="membership.ResortCity"
              :disabled="Disabled"
              :rules="[rules.Empty]"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].ResortCity`,
                  membership.ResortCity
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="3" class="mb-n4">
            <v-text-field
              label="Resort State"
              :id="'ResortState' + idx"
              v-model="membership.ResortState"
              :disabled="Disabled"
              :rules="[rules.Empty]"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].ResortState`,
                  membership.ResortState
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Owner Number"
              :id="'OwnerNumber' + idx"
              v-model="membership.OwnerNumber"
              :disabled="Disabled"
              :rules="[rules.Empty]"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].OwnerNumber`,
                  membership.OwnerNumber
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              :id="'ContractNumber' + idx"
              label="Contract Number"
              v-model="membership.ContractNumber"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].ContractNumber`,
                  membership.ContractNumber
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12">
            <hr
              class="border"
              :style="{
                'border-color': Company.BackgroundColor,
              }"
            />
          </v-col>
          <v-col cols="12">
            <v-row dense>
              <v-col class="d-flex justify-center align-center">
                <b>Do You Own Points Or Weeks?</b>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col class="d-flex justify-center align-center">
                <v-radio-group v-model="membership.Type" row>
                  <v-radio label="Points" :disabled="Disabled" value="Points">
                    Points
                  </v-radio>
                  <v-radio label="Weeks" :disabled="Disabled" value="Weeks">
                    Weeks
                  </v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <h4>
              <b>{{ membership.Type }} Status</b>
            </h4>
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-select
              :id="'CurrentYearWeekStatus' + idx"
              :items="WeekStatus"
              :label="
                membership.CurrentYear + ' ' + membership.Type + ' Status'
              "
              :rules="[rules.Empty]"
              v-model="membership.CurrentYearWeekStatus"
              :disabled="Disabled"
              @blur="
                ValidateField(
                  `Memberships[${idx}].CurrentYearWeekStatus`,
                  membership.CurrentYearWeekStatus
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-select
              :label="membership.NextYear + ' ' + membership.Type + ' Status'"
              v-model="membership.NextYearWeekStatus"
              :disabled="Disabled"
              :id="'NextYearWeekStatus' + idx"
              :items="WeekStatus"
              :rules="[rules.Empty]"
              @blur="
                ValidateField(
                  `Memberships[${idx}].NextYearWeekStatus`,
                  membership.NextYearWeekStatus
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-text-field
              :label="membership.Type + ' Available Every Year'"
              v-model.number="membership.YearlyPoints"
              :disabled="Disabled"
              :rules="[rules.Number]"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].YearlyPoints`,
                  membership.YearlyPoints
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12">
            <hr
              class="border"
              :style="{
                'border-color': Company.BackgroundColor,
              }"
            />
          </v-col>
          <v-col cols="12">
            <h4>
              <b>Membership Financials</b>
            </h4>
          </v-col>
          <!-- Mortgage -->
          <v-col cols="12" lg="4" class="mb-n4">
            <v-text-field
              :id="'MortgageBalance' + idx"
              label="Mortgage Balance"
              v-model.number="membership.MortgageBalance"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].MortgageBalance`,
                  membership.MortgageBalance
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-select
              label="Mortgage Up To Date?"
              :id="'MortgageUpToDate' + idx"
              :items="UpToDate"
              v-model="membership.MortgageUpToDate"
              :disabled="Disabled"
              :rules="[rules.Empty]"
              @blur="
                ValidateField(
                  `Memberships[${idx}].MortgageUpToDate`,
                  membership.MortgageUpToDate
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-menu
              v-model="MortgagePickerActive"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  :id="'MortgagePaidUntil' + idx"
                  v-model="membership.MortgagePaidUntil"
                  label="Mortgage Paid Until"
                  prepend-icon="mdi-calendar"
                  :disabled="Disabled"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="membership.MortgagePaidUntil"
                :rules="[rules.Empty]"
                @input="membership.MortgagePickerActive = false"
                @blur="
                  ValidateField(
                    `Memberships[${idx}].MortgagePaidUntil`,
                    membership.MortgagePaidUntil
                  )
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- Maint Fees -->
          <v-col cols="12" lg="4" class="mb-n4">
            <v-text-field
              :id="'MaintFees' + idx"
              label="Maintenance Fees"
              v-model.number="membership.MaintFees"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].MaintFees`,
                  membership.MaintFees
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-select
              label="Maintenance Fees Up To Date?"
              :id="'MaintUpToDate' + idx"
              :items="UpToDate"
              v-model="membership.MaintUpToDate"
              :disabled="Disabled"
              :rules="[rules.Empty]"
              @blur="
                ValidateField(
                  `Memberships[${idx}].MaintUpToDate`,
                  membership.MaintUpToDate
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-menu
              v-model="MaintPickerActive"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  :id="'MaintPaidUntil' + idx"
                  v-model="membership.MaintPaidUntil"
                  label="Maintenance Fees Paid Until"
                  prepend-icon="mdi-calendar"
                  :disabled="Disabled"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="membership.MaintPaidUntil"
                :rules="[rules.Empty]"
                @input="membership.MaintPickerActive = false"
                @blur="
                  ValidateField(
                    `Memberships[${idx}].MaintPaidUntil`,
                    membership.MaintPaidUntil
                  )
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <!-- Taxes -->
          <v-col cols="12" lg="4" class="mb-n4">
            <v-text-field
              :id="'Taxes' + idx"
              label="Taxes"
              v-model.number="membership.Taxes"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(`Memberships[${idx}].Taxes`, membership.Taxes)
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-select
              label="Taxes Up To Date?"
              :id="'TaxesUpToDate' + idx"
              :items="UpToDate"
              :rules="[rules.Empty]"
              v-model="membership.TaxesUpToDate"
              :disabled="Disabled"
              @keyup="UpdateMemberships"
              @blur="
                ValidateField(
                  `Memberships[${idx}].TaxesUpToDate`,
                  membership.TaxesUpToDate
                )
              "
              outlined
            />
          </v-col>
          <v-col cols="12" lg="4" class="mb-n4">
            <v-menu
              v-model="TaxesPickerActive"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{on, attrs}">
                <v-text-field
                  :id="'TaxesPaidUntil' + idx"
                  v-model="membership.TaxesPaidUntil"
                  label="Taxes Paid Until"
                  prepend-icon="mdi-calendar"
                  :disabled="Disabled"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="membership.MaintPaidUntil"
                :rules="[rules.Empty]"
                @input="membership.MaintPickerActive = false"
                @blur="
                  ValidateField(
                    `Memberships[${idx}].TaxesPaidUntil`,
                    membership.TaxesPaidUntil
                  )
                "
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" v-if="Memberships.length > 1">
            <v-btn
              :disabled="Disabled"
              class="red"
              @click="RemoveMembership(idx)"
            >
              Remove Membership
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn :disabled="Disabled" class="success" @click="PushMembership">
              <span v-if="Memberships.length === 0">Add Membership</span>
              <span v-else>Add Another Membership</span>
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h4>
              <b>Special Instructions / Addendum</b>
            </h4>
            <hr
              class="border"
              :style="{
                'border-color': Company.BackgroundColor,
              }"
            />
          </v-col>
          <v-col
            cols="12"
            v-if="
              Tracking.ShowHalfCost === true &&
              Tracking.ShowInhouseFinancing === false &&
              Tracking.ShowOutsideFinancing === false
            "
          >
            Client agrees to pay half of exit fee ({{
              ((Financial.ClosingCost + Financial.RequiredDownPayment) / 2)
                | toCurrency
            }}) upon contract signing. Remaining half of exit fee ({{
              ((Financial.ClosingCost + Financial.RequiredDownPayment) / 2)
                | toCurrency
            }}) due upon completion of timeshare termination
          </v-col>
          <v-col cols="12" v-if="Tracking.ShowAddendum === true">
            Client to pay transfer and closing of
            {{ Financial.ClosingCost | toCurrency }} upon receipt of invoice in
            approximately 30 days
          </v-col>
          <v-col cols="12">
            <div class="box">
              <p><b>Important Note</b></p>
              <p>
                If the owner chooses to keep or use any weeks, points or credits
                for current year, they will be responsible for all fees
                associated with them, unless specifically noted in the Special
                Instructions and Addendum.
              </p>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import moment from 'moment'
import {mapState} from 'vuex'
import {EventBus} from '@/plugins/EventBus'
export default {
  data() {
    return {
      Financial: {},
      Memberships: {},
      Personal: {},
      Tracking: {},
      MaintPickerActive: false,
      TaxesPickerActive: false,
      MortgagePickerActive: false,
      rules: {
        Empty: (value) => value.length > 0 || 'Can Not Be Blank',
        Phone: (value) => value.length >= 10 || 'Invalid Phone Number',
        Number: (value) => {
          const pattern = /^\d+$/
          return pattern.test(value) || 'Must Be A Number'
        },
      },
    }
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      Company: (state) => state.Agreement.Company,
      Template: (state) => state.Agreement.Template,
      Agreement: (state) => state.Agreement,
      Disabled: (state) => state.Disabled,
      Days: (state) => state.Days,
      Months: (state) => state.Months,
      Years: (state) => state.Years,
      VIPLevels: (state) => state.VIPLevels,
      UpToDate: (state) => state.UpToDate,
      WeekStatus: (state) => state.WeekStatus,
      OwnerStatus: (state) => state.OwnerStatus,
      ExpInterval: (state) => state.ExpInterval,
    }),
  },
  created() {
    this.GetSections()
  },
  beforeUpdate() {
    this.GetSections()
  },
  mounted() {
    this.GetSections()
  },
  methods: {
    ValidateField(Field, Value) {
      var Payload = {
        Field: Field,
        Value: Value,
      }
      EventBus.$emit('ValidateField', Payload)
    },
    GetSections() {
      this.Financial = this.Agreement.Financial
      this.Memberships = this.Agreement.Memberships
      this.Personal = this.Agreement.Personal
      this.Tracking = this.Agreement.Tracking
    },
    PushMembership() {
      const membership = {
        AccountShared: 'No',
        ContractNumber: '',
        CurrentYear: moment(new Date()).startOf('year').format('YYYY'),
        CurrentYearWeekStatus: 'Not Using',
        CurrentYearPoints: 0,
        MaintFees: 0,
        MaintPaidUntil: moment(new Date()).format('YYYY-MM-DD'),
        MaintUpToDate: 'Yes',
        MortgageBalance: 0,
        MortgagePaidUntil: moment(new Date()).format('yyyy-MM-DD'),
        MortgageUpToDate: 'Yes',
        NextYear: moment(new Date())
          .startOf('year')
          .add(1, 'year')
          .format('YYYY'),
        NextYearPoints: 0,
        NextYearWeekStatus: 'Not Using',
        OwnerNumber: '',
        Owners: [
          {
            NameOnDeed: '',
            Status: 'None',
          },
        ],
        Password: '',
        PayChecksTo: '',
        PointsExpDay: '01',
        PointsExpInterval: 'Annualy',
        PointsExpMonth: '01',
        ResortCity: '',
        ResortName: '',
        ResortState: '',
        Taxes: 0,
        TaxesUpToDate: 'Yes',
        TaxesPaidUntil: moment(new Date()).format('yyyy-MM-DD'),
        Type: 'Points',
        UserName: '',
        VIPLevel: 'Plus',
        YearlyPoints: 0,
      }
      this.Memberships.push(membership)
    },
    RemoveMembership(idx) {
      this.Memberships.splice(idx, 1)
    },
    AddOwner(MembershipIdx) {
      var Memberships = this.Memberships
      var owner = {
        NameOnDeed: '',
        Status: 'None',
      }
      Memberships[MembershipIdx].Owners.push(owner)
      this.Memberships = Memberships
    },
    DeleteOwner(MembershipIdx, OwnerIdx) {
      var Memberships = this.Memberships
      Memberships[MembershipIdx].Owners.splice(OwnerIdx, 1)
      this.Memberships = Memberships
    },
    UpdateMemberships() {
      EventBus.$emit('UpdateMemberships', this.Memberships)
    },
  },
}
</script>

<style></style>
