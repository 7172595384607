<template>
  <v-card v-if="Company" light class="pa-5 mb-4">
    <v-row
      :style="{
        background: Company.BackgroundColor,
        color: Company.FontColor,
      }"
      class="d-flex justify-center align-center"
    >
      <span
        class="text-h6"
        :style="{
          background: Company.BackgroundColor,
          color: Company.FontColor,
        }"
      >
        <v-col cols="12" class="d-flex justify-center align-center">
          {{ ReplaceVariables(Template.Card4Title, Agreement) }}
        </v-col>
      </span>
    </v-row>
    <v-row class="box" align="center">
      <v-col cols="12">
        <v-icon x-large>mdi-lock</v-icon>
        <b class="ml-4">Your information is kept private</b>
      </v-col>
      <v-col cols="12">
        All of it, including any potential payment or contact information, is
        transmitted securely via
        <a
          href="https://www.websecurity.digicert.com/security-topics/what-is-ssl-tls-https"
          >SSL</a
        >.
      </v-col>
    </v-row>
    <v-row>
      <!-- Left Side -->
      <v-col cols="12" lg="3">
        <v-row>
          <v-col cols="12">
            <v-icon large :style="{color: Company.IconColor}">
              mdi-card-account-phone
            </v-icon>
            <span class="text-xl-h5 ml-4">
              <b>
                {{
                  ReplaceVariables(Template.Card4Section1Title, Agreement)
                }}</b
              >
            </span>
          </v-col>
          <v-col
            cols="12"
            v-for="(Item, idx) in Template.Card4Section1Paragraphs"
            :key="idx"
          >
            <span>{{ ReplaceVariables(Item.Paragraph, Agreement) }}</span>
          </v-col>
        </v-row>
      </v-col>
      <!-- Right Side -->
      <v-col cols="12" lg="9" class="pa-5" v-if="Personal">
        <v-row dense class="d-flex justify-space-around">
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="First Name"
              placeholder="First Name"
              outlined
              v-model="Personal.FirstName"
              :rules="[rules.FirstName]"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="ValidateField('Personal.FirstName', Personal.FirstName)"
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Last Name"
              placeholder="Last Name"
              outlined
              v-model="Personal.LastName"
              :rules="[rules.LastName]"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="ValidateField('Personal.LastName', Personal.LastName)"
            />
          </v-col>
          <v-col cols="12" lg="12" class="mb-n4">
            <v-text-field
              label="Email"
              placeholder="Email"
              outlined
              v-model="Personal.Email"
              :rules="[rules.Email]"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="ValidateField('Personal.Email', Personal.Email)"
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Phone"
              placeholder="Phone"
              outlined
              v-model="Personal.Phone"
              :rules="[rules.Phone]"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="ValidateField('Personal.Phone', Personal.Phone)"
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Other Phone"
              placeholder="Other Phone"
              outlined
              v-model="Personal.OtherPhone"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="ValidateField('Personal.OtherPhone', Personal.OtherPhone)"
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Street"
              placeholder="Street"
              outlined
              v-model="Personal.Address.Street1"
              :rules="[rules.Empty]"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="
                ValidateField(
                  'Personal.Address.Street1',
                  Personal.Address.Street1
                )
              "
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="Street 2"
              placeholder="Street 2"
              outlined
              v-model="Personal.Address.Street2"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="
                ValidateField(
                  'Personal.Address.Street2',
                  Personal.Address.Street2
                )
              "
            />
          </v-col>
          <v-col cols="12" lg="6" class="mb-n4">
            <v-text-field
              label="City"
              placeholder="City"
              outlined
              v-model="Personal.Address.City"
              :rules="[rules.City]"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="
                ValidateField('Personal.Address.City', Personal.Address.City)
              "
            />
          </v-col>
          <v-col cols="12" lg="3" class="mb-n4">
            <v-text-field
              label="State"
              placeholder="State"
              outlined
              v-model="Personal.Address.State"
              :rules="[rules.State]"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="
                ValidateField('Personal.Address.State', Personal.Address.State)
              "
            />
          </v-col>
          <v-col cols="12" lg="3" class="mb-n4">
            <v-text-field
              label="Zip Code"
              placeholder="Zip Code"
              outlined
              v-model="Personal.Address.ZipCode"
              :rules="[rules.ZipCode]"
              :disabled="Disabled"
              @keyup="UpdatePersonal"
              @blur="
                ValidateField(
                  'Personal.Address.ZipCode',
                  Personal.Address.ZipCode
                )
              "
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import {mapState} from 'vuex'
import {EventBus} from '@/plugins/EventBus'
export default {
  data() {
    return {
      Financial: {},
      Memberships: {},
      Personal: {},
      Tracking: {},
      rules: {
        FirstName: (value) =>
          value.length > 2 || 'First Name Must Be At Least 2 Characters',
        LastName: (value) =>
          value.length > 2 || 'Last Name Must Be At Least 2 Characters',
        Empty: (value) => value.length > 0 || 'Can Not Be Blank',
        Phone: (value) => value.length >= 10 || 'Invalid Phone Number',
        Email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Invalid e-mail.'
        },
        City: (value) =>
          value.length >= 2 || 'City Must Be At Least 2 Characters',
        State: (value) =>
          value.length >= 2 || 'State Must Be At Least 2 Characters',
        ZipCode: (value) =>
          value.length >= 2 || 'Zip Code Must Be At Least 5 Characters',
      },
    }
  },
  computed: {
    ...mapState({
      Client: (state) => state.Client,
      Company: (state) => state.Agreement.Company,
      Template: (state) => state.Agreement.Template,
      Agreement: (state) => state.Agreement,
      Disabled: (state) => state.Disabled,
      Days: (state) => state.Days,
      Months: (state) => state.Months,
      Years: (state) => state.Years,
    }),
  },
  created() {
    this.GetSections()
  },
  beforeUpdate() {
    this.GetSections()
  },
  mounted() {
    this.GetSections()
  },
  methods: {
    ValidateField(Field, Value) {
      var Payload = {
        Field: Field,
        Value: Value,
      }
      EventBus.$emit('ValidateField', Payload)
    },
    GetSections() {
      this.Financial = this.Agreement.Financial
      this.Memberships = this.Agreement.Memberships
      this.Personal = this.Agreement.Personal
      this.Tracking = this.Agreement.Tracking
    },
    UpdatePersonal() {
      EventBus.$emit('UpdatePersonal', this.Personal)
    },
  },
}
</script>

<style></style>
