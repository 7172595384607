import Vue from 'vue'
import VueRouter from 'vue-router'
import Agreement from '@/Views/Agreements/Agreement.vue'
import Dashboard from '@/Views/Dashboard/Dashboard.vue'
import ErrorPage from '@/Views/404/404.vue'
import Login from '@/Views/Login/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '*',
    redirect: '/Error',
  },
  {
    path: '/Error',
    name: 'Error',
    components: {default: ErrorPage},
  },
  {
    path: '/:CompanyId/:ClientId/Login',
    name: 'Login',
    components: {default: Login},
  },
  {
    path: '/:CompanyId/:ClientId/Dashboard',
    name: 'Dashboard',
    components: {default: Dashboard},
  },
  {
    path: '/:CompanyId/:ClientId/:AgreementId',
    name: 'Agreement',
    components: {default: Agreement},
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})

export default router
