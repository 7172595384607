const Joi = require('joi')
const _ = require('lodash')

const schema = Joi.object({
  Financial: Joi.object({
    BankInfo: Joi.object({
      BankAccountNumber: Joi.string().allow(''),
      BankAddress: Joi.string().allow(''),
      BankCheckNumber: Joi.string().allow(''),
      BankName: Joi.string().allow(''),
      BankRoutingNumber: Joi.string().allow(''),
    }),
    Billing: Joi.object({
      NameOnAccount: Joi.string().allow(''),
      Street1: Joi.string().allow(''),
      Street2: Joi.string().allow(''),
      City: Joi.string().allow(''),
      State: Joi.string().allow(''),
      ZipCode: Joi.string().allow(''),
    }),
    Cards: Joi.array()
      .min(1)
      .items(
        Joi.object({
          CCCVC: Joi.string().allow(''),
          CCExpMonth: Joi.string().allow(''),
          CCExpYear: Joi.string().allow(''),
          CCNumber: Joi.string().allow(''),
          CCName: Joi.string().allow(''),
          CCPaymentAmount: Joi.number().precision(2).allow(0),
        })
      ),
    ClosingCost: Joi.number().allow(0),
    FinanceAmount: Joi.number().allow(0),
    FinanceFee: Joi.number().allow(0),
    FinanceRate: Joi.string().allow(''),
    FinanceTerms: Joi.string().allow(''),
    FinanceTotal: Joi.number().precision(2).allow(0),
    MonthlyPayment: Joi.number().precision(2).allow(0),
    RequiredDownPayment: Joi.number().precision(2).allow(0),
    PaymentAmount: Joi.number().precision(2).allow(0),
  }).required(),
  Memberships: Joi.array().items(
    Joi.object({
      AccountShared: Joi.string().allow(''),
      ContractNumber: Joi.string().allow(''),
      CurrentYear: Joi.string().allow(''),
      CurrentYearPoints: Joi.number().integer(),
      CurrentYearWeekStatus: Joi.string().allow(''),
      MaintFees: Joi.number().precision(2),
      MaintPaidUntil: Joi.any().optional(),
      MaintUpToDate: Joi.string().allow(''),
      MortgageBalance: Joi.number().precision(2).allow(0),
      MortgageUpToDate: Joi.string().allow(''),
      MortgagePaidUntil: Joi.any().optional(),
      NextYear: Joi.string().allow(''),
      NextYearPoints: Joi.number().integer().allow(0),
      NextYearWeekStatus: Joi.string().allow(''),
      OwnerNumber: Joi.string().allow(''),
      Owners: Joi.array()
        .min(1)
        .items(
          Joi.object({
            NameOnDeed: Joi.string().allow(''),
            Status: Joi.string().allow(''),
          })
        ),
      Password: Joi.string().allow(''),
      PayChecksTo: Joi.string().allow(''),
      PointsExpDay: Joi.string().allow(''),
      PointsExpInterval: Joi.string().allow(''),
      PointsExpMonth: Joi.string().allow(''),
      ResortCity: Joi.string().allow(''),
      ResortName: Joi.string().allow(''),
      ResortState: Joi.string().allow(''),
      Taxes: Joi.number().precision(2).allow(0),
      TaxesUpToDate: Joi.string().allow(''),
      TaxesPaidUntil: Joi.any().optional(),
      Type: Joi.string().allow(''),
      UserName: Joi.string().allow(''),
      VIPLevel: Joi.string().allow(''),
      YearlyPoints: Joi.number().integer().allow(0),
    })
  ),
  Personal: Joi.object({
    FirstName: Joi.string().allow(''),
    LastName: Joi.string().allow(''),
    Email: Joi.string().email({tlds: false}).allow(''),
    Phone: Joi.string().allow(''),
    OtherPhone: Joi.string().allow(''),
    Address: Joi.object({
      Street1: Joi.string().allow(''),
      Street2: Joi.string().allow(''),
      City: Joi.string().allow(''),
      State: Joi.string().allow(''),
      ZipCode: Joi.string().allow(''),
    }),
  }).required(),
  Signature: Joi.object({
    SignDate: Joi.string().allow(''),
    Signature: Joi.string().allow(''),
    SignatureIP: Joi.string().allow(''),
    SignatureURL: Joi.string().allow(''),
  }),
})

const valid = {
  Financial: {
    BankInfo: {
      BankAccountNumber: 'abc',
      BankAddress: '123 Street',
      BankCheckNumber: 'abc',
      BankName: 'The Bank',
      BankRoutingNumber: 'abc',
    },
    Billing: {
      NameOnAccount: 'John Doe',
      Street1: '123 Street',
      Street2: '',
      City: 'Wildwood',
      State: 'MO',
      ZipCode: '63040',
    },
    Cards: [
      {
        CCCVC: '123',
        CCExpMonth: '01',
        CCExpYear: '2022',
        CCNumber: '1234123412341234',
        CCName: 'John Doe',
        CCPaymentAmount: 0,
      },
    ],
    ClosingCost: 0,
    FinanceAmount: 0,
    FinanceFee: 0,
    FinanceRate: '',
    FinanceTerms: '',
    FinanceTotal: 0,
    MonthlyPayment: 0,
    RequiredDownPayment: 0,
    PaymentAmount: 0,
  },
  Memberships: [
    {
      AccountShared: 'No',
      ContractNumber: '1234567890',
      CurrentYear: '2022',
      CurrentYearWeekStatus: 'Not Using',
      CurrentYearPoints: 50000,
      MaintFees: 1250.52,
      MaintPaidUntil: '2022-01-20',
      MaintUpToDate: 'Yes',
      MortgageBalance: 26728.28,
      MortgagePaidUntil: '2022-01-20',
      MortgageUpToDate: 'Yes',
      NextYear: '2023',
      NextYearPoints: 50000,
      NextYearWeekStatus: 'Not Using',
      OwnerNumber: '123456789',
      Owners: [
        {
          NameOnDeed: 'John Doe',
          Status: 'None',
        },
      ],
      Password: '1sdf3e3561#$',
      PayChecksTo: 'John Doe',
      PointsExpDay: '01',
      PointsExpInterval: 'Annualy',
      PointsExpMonth: '01',
      ResortCity: 'City',
      ResortName: 'Resort',
      ResortState: 'MO',
      Taxes: 182.68,
      TaxesUpToDate: 'Yes',
      TaxesPaidUntil: '2022-01-20',
      Type: 'Points',
      UserName: 'derka22',
      VIPLevel: 'Gold',
      YearlyPoints: 50000,
    },
  ],
  Personal: {
    FirstName: 'John',
    LastName: 'Doe',
    Email: 'Test@Test.com',
    Phone: '1231231234',
    OtherPhone: '1231231234',
    Address: {
      Street1: '123 Street',
      Street2: '',
      City: 'Wildwood',
      State: 'MO',
      ZipCode: '63040',
    },
  },
  Signature: {
    SignDate: '2022-01-20',
    Signature: 'John Doe',
    SignatureIP: '192.168.1.1',
    SignatureURL: 'https://something.com',
  },
}
function Validate(data) {
  return schema.validate(data)
}

function ValidateField(field, value) {
  const cloned = _.cloneDeep(valid)
  const data = _.set(cloned, field, value)
  return schema.validate(data)
}

module.exports = {
  schema,
  valid,
  Validate,
  ValidateField,
}
