import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Client: {},
    Company: {},
    Access: false,
    Agreement: {},
    Disabled: false,
    Months: [
      {
        value: '01',
        text: '01 January',
      },
      {
        value: '02',
        text: '02 February',
      },
      {
        value: '03',
        text: '03 March',
      },
      {
        value: '04',
        text: '04 April',
      },
      {
        value: '05',
        text: '05 May',
      },
      {
        value: '06',
        text: '06 June',
      },
      {
        value: '07',
        text: '07 July',
      },
      {
        value: '08',
        text: '08 August',
      },
      {
        value: '09',
        text: '09 September',
      },
      {
        value: '10',
        text: '10 October',
      },
      {
        value: '11',
        text: '11 November',
      },
      {
        value: '12',
        text: '12 December',
      },
    ],
    Years: [],
    Days: [
      '01',
      '02',
      '03',
      '04',
      '05',
      '06',
      '07',
      '08',
      '09',
      '10',
      '11',
      '12',
      '13',
      '14',
      '15',
      '16',
      '17',
      '18',
      '19',
      '20',
      '21',
      '22',
      '23',
      '24',
      '25',
      '26',
      '27',
      '28',
      '29',
      '30',
      '31',
    ],
    VIPLevels: [
      'Bronze',
      'Plus',
      'Silver',
      'Gold',
      'Platinum',
      'Presidential',
      'Founders',
    ],
    UpToDate: ['Yes', 'No'],
    WeekStatus: ['Not Using', 'Banked', 'Reservations'],
    OwnerStatus: [
      'None',
      'Single',
      'Married',
      'Divorced',
      'Separated',
      'Widow',
      'Deceased',
    ],
    ExpInterval: ['Annualy', 'Even Years', 'Odd Years'],
    ViewDigital: true,
    ViewContacts: false,
  },
  mutations: {
    SetClient(state, client) {
      state.Client = client
    },
    SetCompany(state, company) {
      state.Company = company
    },
    SetAccess(state, access) {
      state.Access = access
    },
    SetAgreement(state, agreement) {
      state.Agreement = agreement
    },
    SetDisabled(state, disabled) {
      state.Disabled = disabled
    },
    SetYears(state, years) {
      state.Years = years
    },
    SetView(state, view) {
      state.ViewDigital = view
    },
    SetViewContacts(state, Payload) {
      state.ViewContacts = Payload.View
      state.Agreement = Payload.Agreement
    },
  },
  actions: {},
  modules: {},
})
