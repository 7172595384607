var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Company)?_c('v-card',{staticClass:"pa-5 mb-4",attrs:{"light":""}},[_c('v-row',{staticClass:"d-flex justify-center align-center",style:({
      background: _vm.Company.BackgroundColor,
      color: _vm.Company.FontColor,
    })},[_c('span',{staticClass:"text-h6",style:({
        background: _vm.Company.BackgroundColor,
        color: _vm.Company.FontColor,
      })},[_c('v-col',{staticClass:"d-flex justify-center align-center",attrs:{"cols":"12"}},[_vm._v(" Memberships ")])],1)]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-icon',{style:({color: _vm.Company.IconColor}),attrs:{"large":""}},[_vm._v(" mdi-account-box ")]),_c('span',{staticClass:"text-xl-h5 ml-4"},[_c('b',[_vm._v(_vm._s(_vm.ReplaceVariables(_vm.Template.Card4Section2Title, _vm.Agreement)))])])],1),_vm._l((_vm.Template.Card4Section2Paragraphs),function(Item,idx){return _c('v-col',{key:idx,attrs:{"cols":"12"}},[_c('span',[_vm._v(_vm._s(_vm.ReplaceVariables(Item.Paragraph, _vm.Agreement)))])])})],2)],1),(_vm.Template.Type === 'Points')?_c('v-col',{staticClass:"pa-5",style:({
        'border-color': _vm.Company.BackgroundColor,
      }),attrs:{"cols":"12","lg":"9"}},[_vm._l((_vm.Memberships),function(membership,idx){return _c('v-row',{key:idx,staticClass:"border"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_c('b',[_vm._v("Miscellaneous")])])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"label":"Make Checks Payable To","placeholder":"Make Checks Payable To","rules":[_vm.rules.Empty],"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].PayChecksTo"),
                membership.PayChecksTo
              )}},model:{value:(membership.PayChecksTo),callback:function ($$v) {_vm.$set(membership, "PayChecksTo", $$v)},expression:"membership.PayChecksTo"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"label":"VIP Status","items":_vm.VIPLevels,"rules":[_vm.rules.Empty],"disabled":_vm.Disabled,"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].VIPLevel"),
                membership.VIPLevel
              )}},model:{value:(membership.VIPLevel),callback:function ($$v) {_vm.$set(membership, "VIPLevel", $$v)},expression:"membership.VIPLevel"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Annual Points Recieved","rules":[_vm.rules.Number],"disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].YearlyPoints"),
                membership.YearlyPoints
              )}},model:{value:(membership.YearlyPoints),callback:function ($$v) {_vm.$set(membership, "YearlyPoints", _vm._n($$v))},expression:"membership.YearlyPoints"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":membership.CurrentYear + ' Points Allocation',"rules":[_vm.rules.Number],"disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].CurrentYearPoints"),
                membership.CurrentYearPoints
              )}},model:{value:(membership.CurrentYearPoints),callback:function ($$v) {_vm.$set(membership, "CurrentYearPoints", _vm._n($$v))},expression:"membership.CurrentYearPoints"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":membership.NextYear + ' Points Allocation',"rules":[_vm.rules.Number],"disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].NextYearPoints"),
                membership.NextYearPoints
              )}},model:{value:(membership.NextYearPoints),callback:function ($$v) {_vm.$set(membership, "NextYearPoints", _vm._n($$v))},expression:"membership.NextYearPoints"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Contract Number","disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].ContractNumber"),
                membership.ContractNumber
              )}},model:{value:(membership.ContractNumber),callback:function ($$v) {_vm.$set(membership, "ContractNumber", $$v)},expression:"membership.ContractNumber"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Owner Number","rules":[_vm.rules.Empty],"disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].OwnerNumber"),
                membership.OwnerNumber
              )}},model:{value:(membership.OwnerNumber),callback:function ($$v) {_vm.$set(membership, "OwnerNumber", $$v)},expression:"membership.OwnerNumber"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"label":"Points Expiration Month","items":_vm.Months,"item-text":"text","item-value":"value","rules":[_vm.rules.Empty],"disabled":_vm.Disabled,"outlined":""},on:{"input":function($event){membership.PointsExpDay = ''},"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].PointsExpMonth"),
                membership.PointsExpMonth
              )}},model:{value:(membership.PointsExpMonth),callback:function ($$v) {_vm.$set(membership, "PointsExpMonth", $$v)},expression:"membership.PointsExpMonth"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"disabled":_vm.Disabled,"items":_vm.Days,"label":"Points Expiration Day","rules":[_vm.rules.Empty],"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].PointsExpDay"),
                membership.PointsExpDay
              )}},model:{value:(membership.PointsExpDay),callback:function ($$v) {_vm.$set(membership, "PointsExpDay", $$v)},expression:"membership.PointsExpDay"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"label":"How Often Do Your Points Expire?","rules":[_vm.rules.Empty],"disabled":_vm.Disabled,"items":_vm.ExpInterval,"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].PointsExpInterval"),
                membership.PointsExpInterval
              )}},model:{value:(membership.PointsExpInterval),callback:function ($$v) {_vm.$set(membership, "PointsExpInterval", $$v)},expression:"membership.PointsExpInterval"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"User Name","rules":[_vm.rules.Empty],"disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].UserName"),
                membership.UserName
              )}},model:{value:(membership.UserName),callback:function ($$v) {_vm.$set(membership, "UserName", $$v)},expression:"membership.UserName"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Password","type":"password","rules":[_vm.rules.Empty],"disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].Password"),
                membership.Password
              )}},model:{value:(membership.Password),callback:function ($$v) {_vm.$set(membership, "Password", $$v)},expression:"membership.Password"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"box pa-4"},[_c('p',[_c('v-icon',{attrs:{"large":"","color":"red"}},[_vm._v("mdi-alert")]),_c('span',{staticClass:"text-xl-h5 ml-4"},[_c('b',[_vm._v("Important")])]),_c('br'),_vm._v(" Do any other companies or individuals use your account or have access to it? ")],1),_c('hr'),_c('p',[_vm._v(" Failure to notify us of any outside activity in your account may cause: ")]),_c('ul',[_c('li',[_vm._v("Reservation cancellations")]),_c('li',[_vm._v("Not enough points being available")]),_c('li',[_vm._v("Confusion and/or undue stress for guests")])]),_c('v-select',{staticClass:"mt-4",attrs:{"label":"Account Shared","rules":[_vm.rules.Empty],"items":_vm.UpToDate,"disabled":_vm.Disabled,"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                  ("Memberships[" + idx + "].AccountShared"),
                  membership.AccountShared
                )}},model:{value:(membership.AccountShared),callback:function ($$v) {_vm.$set(membership, "AccountShared", $$v)},expression:"membership.AccountShared"}})],1)]),(_vm.Memberships.length > 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"red",attrs:{"disabled":_vm.Disabled},on:{"click":function($event){return _vm.RemoveMembership(idx)}}},[_vm._v(" Remove Membership ")])],1):_vm._e()],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"success",attrs:{"disabled":_vm.Disabled},on:{"click":_vm.PushMembership}},[(_vm.Memberships.length === 0)?_c('span',[_vm._v("Add Membership")]):_c('span',[_vm._v("Add Another Membership")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_c('b',[_vm._v("Special Instructions / Addendum")])]),_c('hr',{staticClass:"border",style:({
              'border-color': _vm.Company.BackgroundColor,
            })})]),(
            _vm.Tracking.ShowHalfCost === true &&
            _vm.Tracking.ShowInhouseFinancing === false &&
            _vm.Tracking.ShowOutsideFinancing === false
          )?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Client agrees to pay half of Points Reimbursement Fee of ("+_vm._s(_vm._f("toCurrency")(((_vm.Financial.ClosingCost + _vm.Financial.RequiredDownPayment) / 2)))+") upon contract signing. Remaining half of Points Reimbursement Fee of ("+_vm._s(_vm._f("toCurrency")(((_vm.Financial.ClosingCost + _vm.Financial.RequiredDownPayment) / 2)))+") due once 1st reimbursement check has been received by client. ")]):_vm._e()],1)],2):_c('v-col',{staticClass:"pa-5",style:({
        'border-color': _vm.Company.BackgroundColor,
      }),attrs:{"cols":"12","lg":"9"}},[_vm._l((_vm.Memberships),function(membership,idx){return _c('v-row',{key:idx,staticClass:"border"},[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_c('b',[_vm._v("Miscellaneous")])])]),_c('v-col',{attrs:{"cols":"12"}},_vm._l((membership.Owners),function(owner,idx2){return _c('v-row',{key:idx2,attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"9","lg":"11"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"id":'NameOnDeed' + idx2,"label":"Name On Deed","disabled":_vm.Disabled,"rules":[_vm.rules.Empty],"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                        ("Memberships[" + idx + "].Owners[" + idx2 + "].NameOnDeed"),
                        owner.NameOnDeed
                      )}},model:{value:(owner.NameOnDeed),callback:function ($$v) {_vm.$set(owner, "NameOnDeed", $$v)},expression:"owner.NameOnDeed"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-select',{attrs:{"label":"Status (if applicable)","disabled":_vm.Disabled,"items":_vm.OwnerStatus,"rules":[_vm.rules.Empty],"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                        ("Memberships[" + idx + "].Owners[" + idx2 + "].Status"),
                        owner.Status
                      )}},model:{value:(owner.Status),callback:function ($$v) {_vm.$set(owner, "Status", $$v)},expression:"owner.Status"}})],1)],1)],1),_c('v-col',{staticClass:"mb-4",attrs:{"cols":"3","lg":"1"}},[_c('v-btn',{staticClass:"red tall-button",attrs:{"disabled":_vm.Disabled},on:{"click":function($event){return _vm.DeleteOwner(idx, idx2)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)],1)}),1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-n6",attrs:{"elevation":"8","color":"success","disabled":_vm.Disabled},on:{"click":function($event){return _vm.AddOwner(idx)}}},[_vm._v(" Add Owner ")])],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"id":'ResortName' + idx,"label":"Resort Name","disabled":_vm.Disabled,"rules":[_vm.rules.Empty],"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].ResortName"),
                membership.ResortName
              )}},model:{value:(membership.ResortName),callback:function ($$v) {_vm.$set(membership, "ResortName", $$v)},expression:"membership.ResortName"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{attrs:{"id":'ResortCity' + idx,"label":"Resort City","disabled":_vm.Disabled,"rules":[_vm.rules.Empty],"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].ResortCity"),
                membership.ResortCity
              )}},model:{value:(membership.ResortCity),callback:function ($$v) {_vm.$set(membership, "ResortCity", $$v)},expression:"membership.ResortCity"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"3"}},[_c('v-text-field',{attrs:{"label":"Resort State","id":'ResortState' + idx,"disabled":_vm.Disabled,"rules":[_vm.rules.Empty],"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].ResortState"),
                membership.ResortState
              )}},model:{value:(membership.ResortState),callback:function ($$v) {_vm.$set(membership, "ResortState", $$v)},expression:"membership.ResortState"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"label":"Owner Number","id":'OwnerNumber' + idx,"disabled":_vm.Disabled,"rules":[_vm.rules.Empty],"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].OwnerNumber"),
                membership.OwnerNumber
              )}},model:{value:(membership.OwnerNumber),callback:function ($$v) {_vm.$set(membership, "OwnerNumber", $$v)},expression:"membership.OwnerNumber"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"6"}},[_c('v-text-field',{attrs:{"id":'ContractNumber' + idx,"label":"Contract Number","disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].ContractNumber"),
                membership.ContractNumber
              )}},model:{value:(membership.ContractNumber),callback:function ($$v) {_vm.$set(membership, "ContractNumber", $$v)},expression:"membership.ContractNumber"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('hr',{staticClass:"border",style:({
              'border-color': _vm.Company.BackgroundColor,
            })})]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('b',[_vm._v("Do You Own Points Or Weeks?")])])],1),_c('v-row',{attrs:{"dense":""}},[_c('v-col',{staticClass:"d-flex justify-center align-center"},[_c('v-radio-group',{attrs:{"row":""},model:{value:(membership.Type),callback:function ($$v) {_vm.$set(membership, "Type", $$v)},expression:"membership.Type"}},[_c('v-radio',{attrs:{"label":"Points","disabled":_vm.Disabled,"value":"Points"}},[_vm._v(" Points ")]),_c('v-radio',{attrs:{"label":"Weeks","disabled":_vm.Disabled,"value":"Weeks"}},[_vm._v(" Weeks ")])],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_c('b',[_vm._v(_vm._s(membership.Type)+" Status")])])]),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"id":'CurrentYearWeekStatus' + idx,"items":_vm.WeekStatus,"label":membership.CurrentYear + ' ' + membership.Type + ' Status',"rules":[_vm.rules.Empty],"disabled":_vm.Disabled,"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].CurrentYearWeekStatus"),
                membership.CurrentYearWeekStatus
              )}},model:{value:(membership.CurrentYearWeekStatus),callback:function ($$v) {_vm.$set(membership, "CurrentYearWeekStatus", $$v)},expression:"membership.CurrentYearWeekStatus"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"label":membership.NextYear + ' ' + membership.Type + ' Status',"disabled":_vm.Disabled,"id":'NextYearWeekStatus' + idx,"items":_vm.WeekStatus,"rules":[_vm.rules.Empty],"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].NextYearWeekStatus"),
                membership.NextYearWeekStatus
              )}},model:{value:(membership.NextYearWeekStatus),callback:function ($$v) {_vm.$set(membership, "NextYearWeekStatus", $$v)},expression:"membership.NextYearWeekStatus"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"label":membership.Type + ' Available Every Year',"disabled":_vm.Disabled,"rules":[_vm.rules.Number],"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].YearlyPoints"),
                membership.YearlyPoints
              )}},model:{value:(membership.YearlyPoints),callback:function ($$v) {_vm.$set(membership, "YearlyPoints", _vm._n($$v))},expression:"membership.YearlyPoints"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('hr',{staticClass:"border",style:({
              'border-color': _vm.Company.BackgroundColor,
            })})]),_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_c('b',[_vm._v("Membership Financials")])])]),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"id":'MortgageBalance' + idx,"label":"Mortgage Balance","disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].MortgageBalance"),
                membership.MortgageBalance
              )}},model:{value:(membership.MortgageBalance),callback:function ($$v) {_vm.$set(membership, "MortgageBalance", _vm._n($$v))},expression:"membership.MortgageBalance"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"label":"Mortgage Up To Date?","id":'MortgageUpToDate' + idx,"items":_vm.UpToDate,"disabled":_vm.Disabled,"rules":[_vm.rules.Empty],"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].MortgageUpToDate"),
                membership.MortgageUpToDate
              )}},model:{value:(membership.MortgageUpToDate),callback:function ($$v) {_vm.$set(membership, "MortgageUpToDate", $$v)},expression:"membership.MortgageUpToDate"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":'MortgagePaidUntil' + idx,"label":"Mortgage Paid Until","prepend-icon":"mdi-calendar","disabled":_vm.Disabled,"readonly":"","outlined":""},model:{value:(membership.MortgagePaidUntil),callback:function ($$v) {_vm.$set(membership, "MortgagePaidUntil", $$v)},expression:"membership.MortgagePaidUntil"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.MortgagePickerActive),callback:function ($$v) {_vm.MortgagePickerActive=$$v},expression:"MortgagePickerActive"}},[_c('v-date-picker',{attrs:{"rules":[_vm.rules.Empty]},on:{"input":function($event){membership.MortgagePickerActive = false},"blur":function($event){return _vm.ValidateField(
                  ("Memberships[" + idx + "].MortgagePaidUntil"),
                  membership.MortgagePaidUntil
                )}},model:{value:(membership.MortgagePaidUntil),callback:function ($$v) {_vm.$set(membership, "MortgagePaidUntil", $$v)},expression:"membership.MortgagePaidUntil"}})],1)],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"id":'MaintFees' + idx,"label":"Maintenance Fees","disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].MaintFees"),
                membership.MaintFees
              )}},model:{value:(membership.MaintFees),callback:function ($$v) {_vm.$set(membership, "MaintFees", _vm._n($$v))},expression:"membership.MaintFees"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"label":"Maintenance Fees Up To Date?","id":'MaintUpToDate' + idx,"items":_vm.UpToDate,"disabled":_vm.Disabled,"rules":[_vm.rules.Empty],"outlined":""},on:{"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].MaintUpToDate"),
                membership.MaintUpToDate
              )}},model:{value:(membership.MaintUpToDate),callback:function ($$v) {_vm.$set(membership, "MaintUpToDate", $$v)},expression:"membership.MaintUpToDate"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":'MaintPaidUntil' + idx,"label":"Maintenance Fees Paid Until","prepend-icon":"mdi-calendar","disabled":_vm.Disabled,"readonly":"","outlined":""},model:{value:(membership.MaintPaidUntil),callback:function ($$v) {_vm.$set(membership, "MaintPaidUntil", $$v)},expression:"membership.MaintPaidUntil"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.MaintPickerActive),callback:function ($$v) {_vm.MaintPickerActive=$$v},expression:"MaintPickerActive"}},[_c('v-date-picker',{attrs:{"rules":[_vm.rules.Empty]},on:{"input":function($event){membership.MaintPickerActive = false},"blur":function($event){return _vm.ValidateField(
                  ("Memberships[" + idx + "].MaintPaidUntil"),
                  membership.MaintPaidUntil
                )}},model:{value:(membership.MaintPaidUntil),callback:function ($$v) {_vm.$set(membership, "MaintPaidUntil", $$v)},expression:"membership.MaintPaidUntil"}})],1)],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-text-field',{attrs:{"id":'Taxes' + idx,"label":"Taxes","disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(("Memberships[" + idx + "].Taxes"), membership.Taxes)}},model:{value:(membership.Taxes),callback:function ($$v) {_vm.$set(membership, "Taxes", _vm._n($$v))},expression:"membership.Taxes"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-select',{attrs:{"label":"Taxes Up To Date?","id":'TaxesUpToDate' + idx,"items":_vm.UpToDate,"rules":[_vm.rules.Empty],"disabled":_vm.Disabled,"outlined":""},on:{"keyup":_vm.UpdateMemberships,"blur":function($event){return _vm.ValidateField(
                ("Memberships[" + idx + "].TaxesUpToDate"),
                membership.TaxesUpToDate
              )}},model:{value:(membership.TaxesUpToDate),callback:function ($$v) {_vm.$set(membership, "TaxesUpToDate", $$v)},expression:"membership.TaxesUpToDate"}})],1),_c('v-col',{staticClass:"mb-n4",attrs:{"cols":"12","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"id":'TaxesPaidUntil' + idx,"label":"Taxes Paid Until","prepend-icon":"mdi-calendar","disabled":_vm.Disabled,"readonly":"","outlined":""},model:{value:(membership.TaxesPaidUntil),callback:function ($$v) {_vm.$set(membership, "TaxesPaidUntil", $$v)},expression:"membership.TaxesPaidUntil"}},'v-text-field',attrs,false),on))]}}],null,true),model:{value:(_vm.TaxesPickerActive),callback:function ($$v) {_vm.TaxesPickerActive=$$v},expression:"TaxesPickerActive"}},[_c('v-date-picker',{attrs:{"rules":[_vm.rules.Empty]},on:{"input":function($event){membership.MaintPickerActive = false},"blur":function($event){return _vm.ValidateField(
                  ("Memberships[" + idx + "].TaxesPaidUntil"),
                  membership.TaxesPaidUntil
                )}},model:{value:(membership.MaintPaidUntil),callback:function ($$v) {_vm.$set(membership, "MaintPaidUntil", $$v)},expression:"membership.MaintPaidUntil"}})],1)],1),(_vm.Memberships.length > 1)?_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"red",attrs:{"disabled":_vm.Disabled},on:{"click":function($event){return _vm.RemoveMembership(idx)}}},[_vm._v(" Remove Membership ")])],1):_vm._e()],1)}),_c('v-row',[_c('v-col',[_c('v-btn',{staticClass:"success",attrs:{"disabled":_vm.Disabled},on:{"click":_vm.PushMembership}},[(_vm.Memberships.length === 0)?_c('span',[_vm._v("Add Membership")]):_c('span',[_vm._v("Add Another Membership")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('h4',[_c('b',[_vm._v("Special Instructions / Addendum")])]),_c('hr',{staticClass:"border",style:({
              'border-color': _vm.Company.BackgroundColor,
            })})]),(
            _vm.Tracking.ShowHalfCost === true &&
            _vm.Tracking.ShowInhouseFinancing === false &&
            _vm.Tracking.ShowOutsideFinancing === false
          )?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Client agrees to pay half of exit fee ("+_vm._s(_vm._f("toCurrency")(((_vm.Financial.ClosingCost + _vm.Financial.RequiredDownPayment) / 2)))+") upon contract signing. Remaining half of exit fee ("+_vm._s(_vm._f("toCurrency")(((_vm.Financial.ClosingCost + _vm.Financial.RequiredDownPayment) / 2)))+") due upon completion of timeshare termination ")]):_vm._e(),(_vm.Tracking.ShowAddendum === true)?_c('v-col',{attrs:{"cols":"12"}},[_vm._v(" Client to pay transfer and closing of "+_vm._s(_vm._f("toCurrency")(_vm.Financial.ClosingCost))+" upon receipt of invoice in approximately 30 days ")]):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"box"},[_c('p',[_c('b',[_vm._v("Important Note")])]),_c('p',[_vm._v(" If the owner chooses to keep or use any weeks, points or credits for current year, they will be responsible for all fees associated with them, unless specifically noted in the Special Instructions and Addendum. ")])])])],1)],2)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }